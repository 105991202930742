import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import useHiringTracker from './views/useHiringTracker';
import Table from '../../@common/Table/Table';
import GenericFilter from '../../components/GenericFilter';
import ClearFilters from '../../components/Select/ClearButton';
import { handleTableFilterClear } from '../../constants/HelperConstants';
import { DateRangePicker } from '../../@common/DateRangePicker/DateRangePicker';
import { Modal } from 'antd';
import Timeline from '../Timeline';
import { AiOutlineClose } from 'react-icons/ai';
import { history } from '../../utilities/configureAxios';
import {
  DASHBOARD_HIRING_TRACKER,
  INDIVIDUAL_CANDIDATES
} from '../../constants/RouteConstants';
import PrimaryButton from '../../components/Buttons/Primary';

const HiringTracker = () => {
  const {
    columns,
    loading,
    hiringData,
    status,
    jobStatus,
    showClearFilter,
    handleClearFilter,
    modal,
    closeModal,
    handleCalenderChange,
    calnderValue,
    exportCSV,
    exportCSVLoading
  } = useHiringTracker();
  return (
    <div className='w-full h-screen bg-ghost-white relative'>
      <Header />
      <Helmet>
        <meta charSet='utf-8' />
        <title>Hiring Tracker</title>
      </Helmet>
      <div className='px-[31.5px]'>
        <div className='flex flex-wrap justify-between'>
          <div className='flex flex-wrap gap-[20px]'>
            <GenericFilter
              primaryFilter='All Applications'
              primaryFitlerValue={null}
              values={jobStatus}
              filterKey='status'
              customKey='name'
              currentValue={status ? Number(status) : null}
              disabled={loading}
              hasPrevioudFilter={showClearFilter()}
            />
            <DateRangePicker
              disabled={loading}
              onChange={handleCalenderChange}
              value={calnderValue}
              allowClear={false}
              minDate={'2000-01-01'}
            />
            {showClearFilter() && (
              <ClearFilters handleClear={handleClearFilter} />
            )}
          </div>
          <PrimaryButton
            disabled={hiringData?.data?.length <= 0}
            text='Export'
            onClick={exportCSV}
            loading={exportCSVLoading}
          />
        </div>
        <Table
          columns={columns}
          loading={loading}
          data={hiringData?.data}
          tablePagination={hiringData?.meta?.pagination || {}}
          emptyText='No applications found'
          rowClassName='cursor-pointer'
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(
                  `${INDIVIDUAL_CANDIDATES.replace(
                    ':id',
                    record?.ref_id
                  )}?from=${DASHBOARD_HIRING_TRACKER}`
                );
              }
            };
          }}
        />
        <Modal
          open={modal?.visible}
          onCancel={() => closeModal()}
          destroyOnClose={true}
          footer={null}
          width='80%'
          centered
          className='edit-modal'
        >
          <div className='flex justify-between '>
            <div className='md:px-4'>
              <h3 className='text-lg text-center capitalize font-semibold text-[#233A5C]'>
                Application Status
              </h3>
            </div>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
              onClick={() => closeModal()}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          <div style={{ height: '70vh', overflowY: 'auto' }}>
            <Timeline customSlug={modal?.slug} />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default HiringTracker;
