import { Select, Modal, Input } from 'antd';
import React, { FC, useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import CancelButton from '../../Buttons/Cancel';
import PrimaryButton from '../../Buttons/Primary';
import { useDispatch } from 'react-redux';
import userActions from '../../../redux/users/actions';
import { USER_ROLE } from '../../../constants/HelperConstants';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '../../PhoneNumberInput';

interface modalProps {
  heading?: string;
  userId?: any;
  role?: any;
  mobile?: any;
  status?: any;
}

const EditModal: FC<modalProps> = ({
  heading,
  userId,
  role,
  status,
  mobile
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { Option } = Select;
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState<string>('HR');
  const [newRole, setNewRole] = useState<number>();
  const [contact, setContact] = useState<string>('');
  const [contactError, setContactError] = useState<boolean>(false);
  const [isContactEmpty, setIsContactEmpty] = useState<boolean>(false);

  useEffect(() => {
    if (role === USER_ROLE.HR) {
      setUserRole('HR');
      setNewRole(USER_ROLE.HR);
    } else if (role === USER_ROLE.SUPER_ADMIN) {
      setUserRole('Super Admin');
      setNewRole(USER_ROLE.SUPER_ADMIN);
    } else if (role === USER_ROLE.ORGNIZATION_ADMIN) {
      setUserRole('Organization Admin');
      setNewRole(USER_ROLE.ORGNIZATION_ADMIN);
    } else if (role === USER_ROLE.HR_LEAD) {
      setUserRole('HR Lead');
      setNewRole(USER_ROLE.HR_LEAD);
    }
  }, [role]);

  useEffect(() => {
    if (mobile) {
      setContact('+' + mobile.toString() ?? '');
    } else {
      setContact('');
    }
  }, [mobile, isOpen]);

  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
    setContactError(false);
    setIsContactEmpty(false);
  };

  const handleRoleChange = (value: any) => {
    if (value === 'Organization Admin') {
      setNewRole(USER_ROLE.ORGNIZATION_ADMIN);
    } else if (value === 'HR') {
      setNewRole(USER_ROLE.HR);
    } else if (value === 'HR Lead') {
      setNewRole(USER_ROLE.HR_LEAD);
    }
  };

  const onSave = () => {
    if (contact === '') {
      setIsContactEmpty(true);
      setContactError(false);
    } else if (!isValidPhoneNumber(contact)) {
      setContactError(true);
      setIsContactEmpty(false);
    } else {
      dispatch({
        type: userActions.UPDATE_ROLE,
        payload: {
          id: userId,
          body: { status, role: newRole, contact }
        }
      });
      handleCancel();
    }
  };
  return (
    <>
      <div
        className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
        onClick={showModal}
      >
        <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue font-semibold' />
      </div>
      <Modal
        className='edit-modal'
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <div className='flex justify-between '>
          <div className='md:px-8'>
            <h3 className='text-xl text-center uppercase font-semibold text-blue-radic'>
              {heading}
            </h3>
          </div>

          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        {/* <Select
          className="user-role-select w-[85%] mt-8 ml-8 border-solid border-1 border-[#d9d9d9]"
          onChange={handleRoleChange}
          defaultValue={userRole}
        ></Select> */}

        <Select
          allowClear
          className='hr-select text-base'
          placeholder='Please select'
          onChange={handleRoleChange}
          defaultValue={userRole}
          disabled={status === 0}
        >
          <Option
            className='text-base user-role-select-option'
            value='Organization Admin'
          >
            Organization Admin
          </Option>
          <Option className='text-base user-role-select-option' value='HR Lead'>
            HR Lead
          </Option>
          <Option className='text-base user-role-select-option' value='HR'>
            HR
          </Option>
        </Select>

        {/* <Input
          maxLength={10}
          className='hr-select text-base'
          placeholder='Contact'
          name='contact'
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          disabled={status === 0}
        /> */}

        <PhoneNumberInput
          className='hr-select text-base'
          placeholder='Contact'
          value={contact}
          onChange={setContact}
        />
        <div className='error-message mx-6'>
          {isContactEmpty
            ? 'Contact is required'
            : contactError
            ? 'Please enter a valid phone number'
            : ''}
        </div>

        <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
          <PrimaryButton text='Save' onClick={onSave} disabled={status === 0} />
          <CancelButton onClick={handleCancel} />
        </div>
      </Modal>
    </>
  );
};

export default EditModal;
