import { Empty, Pagination, Select, Spin, Table, Tooltip } from 'antd';
import { Helmet } from 'react-helmet';
import { isNumber } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/Primary';
import Header from '../../components/Header';
import { queryStringToJSON } from '../../utilities/routies';
import { AlignType } from 'rc-table/lib/interface';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { getSearchParamsUrl } from '../../helper';
import { history } from '../../utilities/configureAxios';
import GenericFilter from '../../components/GenericFilter';
import ClearFilters from '../../components/Select/ClearButton';
import JobActions from '../../redux/atsJobs/actions';
import LocationModal from './LocationModal';

const StatusArray = [
  {
    value: 0,
    label: 'Inactive'
  },
  {
    value: 1,
    label: 'Active'
  }
];

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  getAllLocations: state.atsJobsReducers.allLocations,
  location: state.atsJobsReducers.singleLocation
});
const JobLocation: FC<{
  getAllLocations: any;
  loading: any;
  location: any;
}> = ({ getAllLocations, loading, location }) => {
  const { search } = useLocation();
  const { page, q, status } = queryStringToJSON(search);

  const handleStatusChange = (e: any, record: any) => {
    dispatch({
      type: JobActions.EDIT_LOCATION,
      payload: {
        body: { status: e },
        id: record.id
      }
    });
  };
  const columns = [
    {
      title: 'S.no',
      key: 'index',
      dataIndex: 'index',
      align: 'center' as AlignType,
      width: '10%',
      render: (text: any, record: any, index: any) => (
        <>
          <span>
            {((Number(page) ? Number(page) : 1) - 1) * 10 + index + 1}
          </span>
        </>
      )
    },
    {
      title: 'Location',
      key: 'name',
      dataIndex: 'name',
      // ellipsis: true,
      width: '100',
      render: (text: any, record: any, index: any) => (
        <span className='max-w-[5rem]'>
          <Tooltip title={text}>{text}</Tooltip>
        </span>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center' as AlignType,
      width: '10%',

      render: (text: any, record: any, index: any) => (
        <div className='test-select'>
          <Select
            className={`select-box w-[7.5rem] pl-4 table-select-box ${
              text === 1 ? 'table-select-box-success' : 'table-select-box-error'
            }`}
            value={StatusArray[text]}
            onChange={(e: any) => handleStatusChange(e, record)}
          >
            {StatusArray.map((item: any) => (
              <Select.Option
                value={item.value}
                key={item.value}
                className={`select-box-option`}
              >
                <span
                  className={
                    item?.value === 1 ? 'text-[#52c41a]' : 'text-[#ff4d4f]'
                  }
                >
                  {item.label}
                </span>
              </Select.Option>
            ))}
          </Select>
        </div>
      )
    },
    {
      title: 'Actions',
      key: 'acitons',
      dataIndex: '',
      align: 'center' as AlignType,
      // align:'center',
      width: '10%',
      render: (text: any, record: any) => (
        <div className='flex justify-center h-[2rem]'>
          <div
            className={`w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer`}
            onClick={() => onEditLocation(record?.id)}
          >
            <MdOutlineModeEditOutline className={`w-5 h-5  text-light-blue`} />
          </div>
        </div>
      )
    }
  ];
  const handleFilterChange = (value: any) => {
    history.push(
      getSearchParamsUrl({
        page: 1,
        status: isNumber(value) ? String(value) : null
      }),
      {}
    );
  };

  const handleClear = () => {
    history.push(
      getSearchParamsUrl({
        page: 1,
        status: null
      })
    );
  };

  const showClearFilter = () => {
    return !!status;
  };
  const dispatch = useDispatch();
  const [type, setType] = useState<'add' | 'edit'>();
  const [open, setOpen] = useState(false);
  const onCancel = () => {
    dispatch({ type: JobActions.RESET_SINGLE_LOCATION });

    setType(undefined);
    setOpen(false);
  };
  const onAddLocation = () => {
    setType('add');
    setOpen(true);
  };
  const onEditLocation = (id: string) => {
    dispatch({
      type: JobActions.GET_SINGLE_LOCATION,
      payload: {
        id: id
      }
    });
    setType('edit');
    setOpen(true);
  };
  const handleApiRequest = (page: string, q: string, status: string) => {
    dispatch({
      type: JobActions.GET_ALL_LOCATIONS,
      payload: {
        params: {
          page: Number(page) ? Number(page) : 1,
          q: q ? q : null,
          active:
            typeof status !== 'undefined' && status !== null
              ? !!Number(status)
              : null,
          only: true
        }
      }
    });
  };

  useEffect(() => {
    handleApiRequest(page, q, status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, status, q]);

  useEffect(() => {
    return () => {
      dispatch({
        type: JobActions.RESET_STATE
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (body: any) => {
    if (type === 'add') {
      dispatch({
        type: JobActions.POST_LOCATION,
        payload: {
          body: { ...body }
        }
      });
    }
    if (type === 'edit') {
      dispatch({
        type: JobActions.EDIT_LOCATION,
        payload: {
          body: { ...body },
          id: location.id
        }
      });
    }
  };

  return (
    <>
      <div className='w-full h-screen overflow-y-auto bg-ghost-white relative'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Location</title>
        </Helmet>
        <Header />
        <div className='px-[31.5px]'>
          <div className='flex justify-between'>
            <div className='flex'>
              <GenericFilter
                primaryFilter='All Locations'
                primaryFitlerValue={null}
                values={StatusArray}
                currentValue={status ? Number(status) : null}
                onSelection={(value: number) => {
                  handleFilterChange(value);
                }}
              />
              {/* ---Clear filter button-- */}
              {showClearFilter() && <ClearFilters handleClear={handleClear} />}
            </div>
            <PrimaryButton text='Add Location' onClick={onAddLocation} />
          </div>
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <div className='shadow-lg overflow-y-hidden min-h-[80%] mt-[20px] rounded-lg'>
              <Table
                locale={{
                  emptyText: (
                    <p className='w-full  xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center flex-col'>
                      <Empty description={false} />
                      <span className=''>No locations available</span>
                    </p>
                  )
                }}
                columns={columns}
                showHeader={true}
                dataSource={
                  getAllLocations?.data?.length > 0 ? getAllLocations?.data : []
                }
                scroll={{ x: true }}
                pagination={false}
                rowClassName={'bg-white text-blue-zodiac max-h-[3.75rem]'}
              />
            </div>
          </Spin>

          <div className='flex justify-center py-5'>
            <Pagination
              showSizeChanger={false}
              current={Number(page) ? Number(page) : 1}
              pageSize={10}
              onChange={(page) =>
                history.push(
                  getSearchParamsUrl({ page: Number(page) ? Number(page) : 1 }),
                  {}
                )
              }
              total={getAllLocations?.meta?.pagination?.count}
            />
          </div>
        </div>
      </div>
      <LocationModal
        type={type}
        open={open}
        onCancel={onCancel}
        onSave={onSave}
      />
    </>
  );
};

export default connect(mapStateToProps)(JobLocation);
