import { Modal } from 'antd';
import { FC, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import Inputfield from '../../InputField';
import {
  EMAIL_REGEX,
  PHONENUM_REGEX
} from '../../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  open: state.applications.updateStatusModal,
  loading: state.applications.loading,
  formErrors: state.applications.formErrors,
  rejectedReason: state.applications.rejectedReason,
  currentStatus: state.applications.currentStatus
});

interface IUpdateStatus {
  singleApplication: any;
  id?: string;
  currentStatus: any;
  open: any;
  formErrors?: any;
  loading?: any;
  rejectedReason?: string;
  apiCall?: () => void;
}

const UpdateStatusModal: FC<IUpdateStatus> = ({
  singleApplication,
  currentStatus,
  formErrors,
  open,
  loading,
  id,
  rejectedReason,
  apiCall
}) => {
  const dispatch = useDispatch();
  const [alias, setAlias] = useState<any>('');
  const handleUpdate = () => {
    dispatch({
      type: applicationActions.UPDATE_STATUS_OF_APPLICATION,
      payload: {
        ref_id: id || singleApplication.id,
        body: {
          status: currentStatus,
          rejection_reason: rejectedReason,
          email_alias_to: alias.match(EMAIL_REGEX) ? alias : null,
          contact_alias_to: alias.match(PHONENUM_REGEX) ? Number(alias) : null
        },
        custom: Boolean(apiCall),
        cb: () => {
          if (apiCall) {
            apiCall();
            setAlias('');
          } else {
            setAlias('');
          }
        }
      }
    });
  };
  const handleCancel = () => {
    dispatch({
      type: applicationActions.TOGGLE_UPDATE_STATUS_MODAL,
      payload: false
    });
    if (formErrors?.aliasTo?.length) {
      dispatch({
        type: applicationActions.SET_STATE,
        payload: {
          formErrors: {}
        }
      });
    }
    setAlias('');
  };
  return (
    <>
      <Modal
        className='delete-modal'
        centered
        destroyOnClose={true}
        visible={open}
        onCancel={() => {
          dispatch({
            type: applicationActions.TOGGLE_UPDATE_STATUS_MODAL,
            payload: false
          });
          if (formErrors?.aliasTo?.length) {
            dispatch({
              type: applicationActions.SET_STATE,
              payload: {
                formErrors: {}
              }
            });
          }
          setAlias('');
        }}
      >
        <div className='flex justify-between'>
          <div className='px-4 md:px-10'>
            <h3 className='text-xl uppercase font-semibold text-blue-radic'>
              Update Status
            </h3>
          </div>
        </div>
        <div className='mt-10 md:mt-10 px-4 md:px-10'>
          <p className='text-base text-blue-radic text-center lg:text-left'>
            Are you sure you want to update the status ?
          </p>
        </div>
        {+currentStatus === 24 ? (
          <div style={{ padding: '0px 40px' }}>
            <div className='flex-grow'>
              <Inputfield
                className={`edit-input text-base ${
                  (alias?.length &&
                    !(
                      alias.match(EMAIL_REGEX) || alias.match(PHONENUM_REGEX)
                    )) ||
                  formErrors?.aliasTo?.length
                    ? 'edit-inputError'
                    : ''
                }`}
                value={alias}
                name='aliasTo'
                label='Alias To'
                onChange={(e: any) => {
                  if (formErrors?.aliasTo?.length) {
                    dispatch({
                      type: applicationActions.SET_STATE,
                      payload: {
                        formErrors: {}
                      }
                    });
                  }
                  setAlias(e.target.value);
                }}
                placeholder='Enter Email/Phone number'
                required
                helperText={
                  formErrors?.aliasTo?.length
                    ? formErrors?.aliasTo
                    : alias?.length &&
                      !(alias.match(EMAIL_REGEX) || alias.match(PHONENUM_REGEX))
                    ? 'Please enter valid email/phone number'
                    : null
                }
              />
            </div>
          </div>
        ) : null}
        <div className='flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7'>
          <button
            className={
              loading
                ? 'disableyesBtn'
                : +currentStatus === 24
                ? !(alias.match(EMAIL_REGEX) || alias.match(PHONENUM_REGEX)) ||
                  formErrors?.aliasTo?.length ||
                  loading
                  ? 'disableyesBtn'
                  : 'yesBtn'
                : 'yesBtn'
            }
            onClick={handleUpdate}
            disabled={
              loading
                ? true
                : +currentStatus === 24
                ? !(alias.match(EMAIL_REGEX) || alias.match(PHONENUM_REGEX)) ||
                  formErrors?.aliasTo?.length ||
                  loading
                : false
            }
          >
            Yes
          </button>
          <button className='noBtn' onClick={handleCancel}>
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(UpdateStatusModal);
