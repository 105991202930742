/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import { Pagination, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import referralActions from '../../../redux/referrals/actions';
import {
  formatsDate,
  getCurrentQueryParams,
  getSearchParamsUrl
} from '../../../helper';
import { history } from '../../../utilities/configureAxios';
import AddToATS from '../../Modal/AddToATS';
import AddReferral from '../../Modal/AddReferral';
import DeleteReferral from '../../Modal/Delete/deleteReferral';
import EditReferral from '../../Modal/Edit/EditReferral';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import ViewReferral from '../../Modal/ViewDetails/ViewReferral';
import moment from 'moment';
import { referralsFilterArray } from '../../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  allReferrals: state.referrals.allReferrals,
  loading: state.auth.loading
});

interface ICandidateTable {
  allReferrals: any;
  loading: boolean;
  location: any;
}

const ReferralTable: React.FC<ICandidateTable> = ({
  allReferrals,
  loading,
  location
}) => {
  const { page, q } = getCurrentQueryParams();
  const dispatch = useDispatch();

  // const after = location.search.substring(location.search.indexOf('=%') + 1);

  // useEffect(() => {
  //   dispatch({
  //     type: referralActions.GET_ALL_HR_REFERRALS,
  //     payload: {
  //       page: page ?? 1
  //       q: q ?? ''
  //     }
  //   });
  // }, [page, dispatch]);

  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <div className='flex flex-col'>
          <div className='bg-white shadow-lg mx-8 rounded-2xl overflow-x-auto min-h-[80%] '>
            <table className='w-full table_transition'>
              <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                <tr className=' text-[0.9rem] px-2'>
                  <th className='text-[#233A5C] text-center text-opacity-50'>
                    S.No
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4'>
                    Referral
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4'>Email</th>
                  <th className='text-[#233A5C] text-opacity-50 py-4'>
                    Referred By
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4'>
                    Referred On
                  </th>
                  <th className='text-[#233A5C] text-center text-opacity-50 py-4'>
                    Ref Type
                  </th>
                  <th className='text-[#233A5C] text-center text-opacity-50 py-4'>
                    Job Title
                  </th>
                  <th className='py-4 w-[10rem] text-center text-[#233A5C] text-opacity-50'>
                    Status
                  </th>
                  <th className='text-[#233A5C] text-center text-opacity-50 py-4'>
                    Actions
                  </th>
                  {/* <th className="py-4 w-full text-[#233A5C] text-opacity-50"></th> */}
                </tr>
              </thead>
              <tbody>
                {allReferrals &&
                  allReferrals?.data?.length > 0 &&
                  allReferrals?.data?.map((d: any, i: any) => (
                    <tr
                      key={d.uuid}
                      className='text-base border-bottom text-blue-zodiac table-text  font-normal capitalize mb-0  whitespace-nowrap overflow-auto'
                    >
                      <td
                        className={
                          'py-2 pr-2 min-w-[6rem] max-w-[10rem] text-overflow-none text-center'
                        }
                      >
                        {allReferrals?.meta?.pagination?.perPage *
                          (allReferrals?.meta?.pagination?.page - 1) +
                          i +
                          1}
                      </td>
                      <td
                        className={
                          'py-2 pr-2 min-w-[10rem] max-w-[15rem] text-overflow-none overflow-ellipsis'
                        }
                      >
                        <Tooltip placement='bottomLeft' title={d?.full_name}>
                          <span className='max-w-[120px] truncate block'>
                            {d?.full_name}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        className={
                          'py-2 pr-2 min-w-[10rem] max-w-[15rem] lowercase text-overflow-none overflow-ellipsis'
                        }
                      >
                        <Tooltip placement='bottomLeft' title={d?.email}>
                          <span className='max-w-[120px] block truncate'>
                            {d?.email}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        className={
                          'py-2 pr-2 min-w-[10rem] max-w-[15rem] text-overflow-none'
                        }
                      >
                        <Tooltip
                          placement='bottomLeft'
                          title={d?.referred_by ?? d?.user_name}
                        >
                          <span className='max-w-[120px] block truncate'>
                            {d?.referred_by ?? d?.user_name}
                          </span>
                        </Tooltip>
                      </td>

                      <td
                        className={
                          'py-2 pr-2 min-w-[10rem] max-w-[15rem] text-overflow-none'
                        }
                      >
                        <Tooltip
                          placement='bottomLeft'
                          title={
                            d?.created_at !== null &&
                            moment(d?.created_at).format(formatsDate)
                          }
                        >
                          <span className='max-w-[120px] block truncate'>
                            {d?.created_at !== null
                              ? moment(d?.created_at).format(formatsDate)
                              : 'N.A'}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        className={
                          'py-2 pr-2 min-w-[10rem] max-w-[15rem] text-center text-overflow-none'
                        }
                      >
                        {d?.type}
                      </td>
                      <td
                        className={
                          'py-2 min-w-[10rem] max-w-[15rem] text-center text-overflow-none overflow-ellipsis'
                        }
                      >
                        <Tooltip placement='bottomLeft' title={d?.job_title}>
                          <span className='max-w-[120px] truncate'>
                            {d?.job_title}
                          </span>
                        </Tooltip>
                      </td>
                      <td className='py-2 min-w-[10rem] max-w-[15rem] text-center '>
                        <p
                          className={
                            d?.status === 0
                              ? 'text-light-blue mb-0 pb-0'
                              : d?.status === 2
                              ? 'text-[#ff4d4f] mb-0 pb-0'
                              : 'text-[#52c41a] mb-0 pb-0'
                          }
                        >
                          {referralsFilterArray[d?.status].name}
                        </p>
                      </td>
                      <td className='py-2 pr-4 pb-2 w-full min-w-[10rem] max-w-[25rem] text-center flex gap-4 mr-0'>
                        <ViewReferral
                          application_slug={d?.application_slug}
                          heading='Referral Details'
                          id={d.uuid}
                          status={d.status}
                        />
                        <AddToATS
                          heading='Add Application'
                          page={page}
                          id={d.uuid}
                          status={d.status}
                        />
                        <EditReferral
                          page={page}
                          heading='Edit Referral'
                          id={d.uuid}
                          status={d.status}
                          flag='admin_edit'
                        />
                        <DeleteReferral
                          heading='Referral To Be Marked Invalid'
                          desc='Are you sure you want to mark this referral as invalid?'
                          userId={d.uuid}
                          data={d}
                          page={page}
                          status={d.status}
                          flag='admin_delete'
                        />

                        {/* <Tooltip
                          placement='topRight'
                          title={
                            d?.status === 1
                              ? 'Referral Accepted'
                              : d?.description
                          }
                        >
                          <div
                            style={{
                              backgroundColor: '#2D94F31A',
                              width: '32px',
                              height: '32px',
                              borderRadius: '5px',
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <AiFillInfoCircle
                              style={{ fontSize: '20px', color: '#1890FF' }}
                            />
                          </div>
                        </Tooltip> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {allReferrals && allReferrals?.data?.length === 0 && (
              <div className='bg-white mx-5 min-h-[60vh] relative rounded-[20px]'>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-2'>
                  <img src='/TableEmptyState.svg' alt='' />
                  <h3 className='flex justify-center text-[#0E254B] text-opacity-40 text-[1.2rem] font-bold'>
                    No Referrals has been found yet.
                  </h3>
                  <div className='flex justify-center mr-[32px] mb-4'>
                    <AddReferral heading='Add Referral' />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='flex justify-center py-5'>
            <Pagination
              showSizeChanger={false}
              current={allReferrals?.meta?.pagination?.page}
              pageSize={10}
              onChange={(page) =>
                history.push(getSearchParamsUrl({ page: page, q: q }), {})
              }
              total={allReferrals?.meta?.pagination?.count}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default connect(mapStateToProps)(ReferralTable);
