import Header from '../../components/Header';
import { Button, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import { BiSync } from 'react-icons/bi';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SettingsTable from '../../components/Table/SettingsTable';
import jobActions from '../../redux/jobs/actions';
import PrimaryButton from '../../components/Buttons/Primary';
import { DASHBOARD_ADD_JOB } from '../../constants/RouteConstants';
import { history } from '../../utilities/configureAxios';
import GenericFilter from '../../components/GenericFilter';
import ClearFilters from '../../components/Select/ClearButton';
import { getSearchParamsUrl } from '../../helper';
import { queryStringToJSON } from '../../utilities/routies';
import { isNumber } from 'lodash';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import atsJobService from '../../services/atsJobs';
import { useState } from 'react';

const mapStateToProps = (state: any) => ({
  jobs: state.jobs.jobs,
  loading: state.auth.loading
});
interface IJOBS {
  jobs: any;
  location: any;
  loading: boolean;
}
const Jobs = ({ jobs, loading }: IJOBS) => {
  // const history = useHistory();
  const jobService = new atsJobService();
  const [open, setOpen] = useState<{
    visible?: boolean;
    status?: string;
    id?: string;
  }>({ visible: false });
  const { search } = useLocation();
  const { page, q, status, org_id, label } = queryStringToJSON(search);
  const [reset, setReset] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSync = () => {
    dispatch({
      type: jobActions.SYNC_ALL_JOBS,
      payload: page
    });
  };
  const handleCancel = () => {
    setOpen({
      visible: false,
      status: '',
      id: ''
    });
  };
  const handleClear = () => {
    setReset(true);
    history.push(
      getSearchParamsUrl({
        page: 1,
        status: null,
        org_id: null,
        label: null
      })
    );
  };
  const showClearFilter = () => {
    return !!status || !!org_id;
  };
  const handleFilterChange = (key: string, value: any) => {
    let payload: any = {
      page: 1,
      q,
      org_id
    };

    payload[key] = isNumber(value) ? String(value) : null;

    history.push(
      getSearchParamsUrl(key?.length ? payload : { ...value, page: 1 })
    );
  };
  const handleModalOpen = (userId: string, status: string) => {
    setOpen({
      id: userId,
      visible: true,
      status
    });
  };
  const handleUpdate = () => {
    if (open?.status === 'Active') {
      dispatch({
        type: jobActions.PATCH_JOB,
        payload: {
          id: open?.id,
          body: { status: 1 }
        }
      });
    } else if (open?.status === 'Inactive') {
      dispatch({
        type: jobActions.PATCH_JOB,
        payload: {
          id: open?.id,
          body: { status: 0 }
        }
      });
    }
    handleCancel();
  };
  return (
    <div className='w-full h-screen overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Jobs</title>
      </Helmet>
      <Header />
      <div className='my-5 mx-8 flex justify-between'>
        <div className='flex flex-wrap gap-[20px]'>
          <GenericFilter
            primaryFilter='All Job Status'
            primaryFitlerValue={null}
            values={[
              { label: 'Active', value: 1 },
              { label: 'Inactive', value: 0 }
            ]}
            currentValue={status?.length ? Number(status) : null}
            onSelection={(value: number) => {
              handleFilterChange('status', value);
            }}
          />
          <div className='flex flex-col min-w-[10rem] shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
            <AutoComplete
              apiService={jobService.GetAllOrgs}
              key='org_id'
              placeholder='Select organization'
              setselectedValue={(data: any) => {
                setReset(false);
                if (data) {
                  handleFilterChange('', {
                    org_id: data?.id || null,
                    label: data?.name || null
                  });
                } else {
                  handleFilterChange('', {
                    org_id: null,
                    label: null
                  });
                }
              }}
              defaultValue={
                org_id?.length && isNumber(Number(org_id)) && label?.length
                  ? `${org_id}~${label}`
                  : ''
              }
              reset={reset}
              style={{}}
            />
          </div>

          {showClearFilter() && <ClearFilters handleClear={handleClear} />}
        </div>
        {/* ---Clear filter button-- */}
        <div className='mx-2'>
          <PrimaryButton
            text='Add Job'
            onClick={() => {
              history.push(DASHBOARD_ADD_JOB);
            }}
          />
        </div>
        {/* <AddJobModal heading="Add job" /> */}
      </div>

      <SettingsTable
        jobs={jobs}
        loading={loading}
        location={history.location}
        handleModalOpen={handleModalOpen}
      />
      <Modal
        className='delete-modal'
        centered
        destroyOnClose={true}
        visible={open?.visible}
        onCancel={handleCancel}
      >
        <div className='flex justify-between'>
          <div className='px-4 md:px-10'>
            <h3 className='text-xl uppercase font-semibold text-blue-radic'>
              Update Status
            </h3>
          </div>
        </div>
        <div className='mt-10 md:mt-10 px-4 md:px-10'>
          <p className='text-base text-blue-radic text-center lg:text-left'>
            Are you sure you want to update the status ?
          </p>
        </div>
        <div className='flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7'>
          <button
            className={loading ? 'disableyesBtn' : 'yesBtn'}
            onClick={handleUpdate}
            disabled={loading}
          >
            Yes
          </button>
          <button className='noBtn' onClick={handleCancel}>
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps)(Jobs);
