import { Input, Modal, Select } from 'antd';
import React, { FC, useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import CancelButton from '../../Buttons/Cancel';
import PrimaryButton from '../../Buttons/Primary';
import userActions from '../../../redux/users/actions';
import jobActions from '../../../redux/jobs/actions';
import interviewerActions from '../../../redux/interviewers/actions';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ADMIN_HR_MANAGEMENT,
  DASHBOARD_INTERVIEWER
} from '../../../constants/RouteConstants';
import { USER_ROLE } from '../../../constants/HelperConstants';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '../../PhoneNumberInput';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading
});

interface AddProps {
  heading: string;
}

interface InputProps {
  inputHeading: string;
  placeholder: string;
}

interface InputDetails {
  inputDetails: InputProps[];
  loading: boolean;
}

interface IHrObj {
  name: string;
  email: string;
  contact: string;
  job_title: string;
  role: number;
}

interface IJobObj {
  job_title: string;
}

interface IIvObj {
  name: string;
  email: string;
  job_title: string;
}

const AddModal: FC<InputDetails & AddProps> = ({
  inputDetails,
  heading,
  loading
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hrObj, setHrObj] = useState<IHrObj>({
    email: '',
    name: '',
    contact: '',
    job_title: '',
    role: 3
  });
  const [newRole, setNewRole] = useState<number>(3);
  const [jobObj, setJobObj] = useState<IJobObj>({ job_title: '' });
  const [ivObj, setIvObj] = useState<IIvObj>({
    email: '',
    name: '',
    job_title: ''
  });

  // Error States
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false);
  const [nameLengthError, setNameLengthError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [jobTitleError, setJobTitleError] = useState<boolean>(false);
  const [contactError, setContactError] = useState<boolean>(false);
  const [contactLengthError, setContactLengthError] = useState<boolean>(false);
  const [roleError, setRoleError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const showModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setEmailError(false);
      setIsEmailEmpty(false);
      setNameLengthError(false);
      setContactError(false);
      setContactLengthError(false);
      setNameError(false);
      setJobTitleError(false);
      setRoleError(false);
      setHrObj({
        email: '',
        name: '',
        contact: '',
        job_title: '',
        role: 3
      });
      setJobObj({ job_title: '' });
      setIvObj({ name: '', email: '', job_title: '' });
    }
  }, [isOpen]);

  const handlePhoneInputChange = (value: any) => {
    setHrObj({ ...hrObj, contact: value });
    setContactError(false);
    setContactLengthError(false);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'Name':
        setHrObj({ ...hrObj, name: value });
        setNameLengthError(false);
        setNameError(false);
        setIvObj({ ...ivObj, name: value });
        break;
      case 'Email':
        setHrObj({ ...hrObj, email: value });
        setEmailError(false);
        setIsEmailEmpty(false);
        setIvObj({ ...ivObj, email: value });
        break;
      case 'Job Title':
        setHrObj({ ...hrObj, job_title: value });
        setJobTitleError(false);
        setJobObj({ job_title: value });
        setIvObj({ ...ivObj, job_title: value });
        break;
      default:
        break;
    }
  };

  const validateHrObj = () => {
    let valid = true;
    if (!hrObj.name || hrObj.name.trim().length < 3 || hrObj.name.length > 50) {
      setNameLengthError(true);
      valid = false;
    } else if (!/^[a-zA-Z ]*$/.test(hrObj.name)) {
      setNameError(true);
      valid = false;
    }

    if (!isValidPhoneNumber(hrObj.contact.toString() ?? '')) {
      setContactError(true);
      valid = false;
    }
    if (hrObj.contact === '') {
      setContactLengthError(true);
      valid = false;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(hrObj.email)) {
      setEmailError(true);
      valid = false;
    }

    if (hrObj.email === '') {
      setIsEmailEmpty(true);
      valid = false;
    }

    if (!hrObj.role) {
      setRoleError(true);
      valid = false;
    }

    if (hrObj.job_title.trim() === '') {
      setJobTitleError(true);
      valid = false;
    }

    return valid;
  };

  const validateIvObj = () => {
    let valid = true;
    if (!ivObj.name || ivObj.name.trim().length < 3 || ivObj.name.length > 50) {
      setNameLengthError(true);
      valid = false;
    } else if (!/^[a-zA-Z ]*$/.test(ivObj.name)) {
      setNameError(true);
      valid = false;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ivObj.email)) {
      setEmailError(true);
      valid = false;
    }

    if (ivObj.email === '') {
      setIsEmailEmpty(true);
      valid = false;
    }

    if (ivObj.job_title.trim() === '') {
      setJobTitleError(true);
      valid = false;
    }

    return valid;
  };

  const onAdd = () => {
    if (validateHrObj()) {
      dispatch({ type: userActions.POST_INVITE_MAIL, payload: hrObj });
      onCancel();
    }
  };

  const handleAddJob = () => {
    if (jobObj.job_title.length === 0) {
      setJobTitleError(true);
    } else {
      dispatch({ type: jobActions.POST_JOB, payload: jobObj });
      onCancel();
    }
  };

  const onAddInterviewer = () => {
    if (validateIvObj()) {
      dispatch({ type: interviewerActions.POST_INTERVIEWERS, payload: ivObj });
      onCancel();
    }
  };

  const handleClick = () => {
    if (location.pathname === `${ADMIN_HR_MANAGEMENT}`) {
      onAdd();
    } else if (location.pathname === `${DASHBOARD_INTERVIEWER}`) {
      onAddInterviewer();
    }
  };

  const handleRoleChange = (value: any) => {
    let role = USER_ROLE.HR;
    if (value === 'HR Lead') {
      role = USER_ROLE.HR_LEAD;
    }
    setNewRole(role);
    setHrObj({ ...hrObj, role });
    setRoleError(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <PrimaryButton text={heading} onClick={showModal} />
      <Modal
        className='edit-modal'
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <div className='flex justify-between'>
          <div className='md:px-8'>
            <h3 className='text-xl capitalize font-semibold text-blue-radic'>
              {heading}
            </h3>
          </div>
          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        {inputDetails?.map((d, i) => (
          <div className='pt-8 md:px-8 -space-y-2 relative' key={i}>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              {d.inputHeading}
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            {d.placeholder === 'Select Role' ? (
              <Select
                allowClear
                className='hr-select hr-add-select !m-0 text-base'
                placeholder={d.placeholder}
                onChange={handleRoleChange}
                defaultValue='HR'
              >
                <Select.Option
                  className='text-base user-role-select-option'
                  value='HR'
                >
                  HR
                </Select.Option>
                <Select.Option
                  className='text-base user-role-select-option'
                  value='HR Lead'
                >
                  HR Lead
                </Select.Option>
              </Select>
            ) : d.placeholder === 'Contact' ? (
              <PhoneNumberInput
                className='edit-input text-base'
                placeholder={d.placeholder}
                name={d.placeholder}
                onChange={handlePhoneInputChange}
              />
            ) : (
              <Input
                required
                type={d.placeholder === 'Email' ? 'email' : 'text'}
                className='edit-input tex-base'
                placeholder={d.placeholder}
                name={d.placeholder}
                onChange={handleInputChange}
              />
            )}
            {d.placeholder === 'Email' && (
              <div className='error-message absolute bottom-[-20px]'>
                {isEmailEmpty
                  ? 'Email is Required'
                  : emailError
                  ? 'Please enter a valid email'
                  : null}
              </div>
            )}
            {d.placeholder === 'Name' && (
              <div className='error-message absolute bottom-[-20px]'>
                {nameLengthError
                  ? 'Name should be min 3 & max 50 characters long'
                  : null}
                {nameError ? 'Invalid name' : null}
              </div>
            )}
            {d.placeholder === 'Contact' && (
              <div className='error-message absolute bottom-[-20px]'>
                {contactLengthError
                  ? 'Contact is Required'
                  : contactError
                  ? 'Please enter a valid phone number'
                  : null}
              </div>
            )}
            {d.placeholder === 'Job Title' && (
              <div className='error-message absolute bottom-[-20px]'>
                {jobTitleError ? 'Job Title is required' : null}
              </div>
            )}
          </div>
        ))}
        <div className='flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7'>
          <PrimaryButton text='Add' onClick={handleClick} />
          <CancelButton onClick={onCancel} />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(AddModal);
