import { Select, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { history } from '../../utilities/configureAxios';
import { getSearchParamsUrl } from '../../helper';
import { CustomObject } from '../../Interfaces/common';
import { useLocation } from 'react-router-dom';

interface IGenericFilter {
  primaryFilter: string;
  containerWidth?: string;
  primaryFitlerValue: any;
  values?: any;
  onSelection?: (value: any) => void;
  currentValue?: any;
  filterKey?: string;
  customKey?: string;
  disabled?: boolean;
  hasPrevioudFilter?: boolean;
}

const GenericFilter: FC<IGenericFilter> = ({
  primaryFilter,
  primaryFitlerValue,
  values,
  onSelection,
  currentValue,
  filterKey,
  customKey = 'label',
  disabled = false,
  hasPrevioudFilter
}) => {
  const { Option } = Select;
  const selectChange = (value: any) => {
    const payload: CustomObject = { page: 1 };
    payload[filterKey || ''] = value;
    history.push(getSearchParamsUrl(payload, Boolean(value)));
  };
  const [isDefaultValue, setIsDefaultValue] = useState<boolean>(true);

  const locationURL = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(locationURL.search);
    const hasSelectedParam = searchParams.has('status');
    if (hasSelectedParam) {
      setIsDefaultValue(false);
    } else {
      setIsDefaultValue(true);
    }
  }, [locationURL]);

  return (
    <div className='flex flex-col min-w-[10rem] shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
      <Select
        className={`${isDefaultValue && 'faded-select'} filter-select w-full`}
        value={currentValue}
        onChange={onSelection ? onSelection : selectChange}
        placeholder={primaryFilter}
        disabled={disabled}
      >
        <Option
          className='filter-select-option truncate'
          value={primaryFitlerValue}
        >
          <Tooltip title={primaryFilter}>
            <span className='w-full truncate'>{primaryFilter}</span>
          </Tooltip>
        </Option>
        {values?.map((item: any) => (
          <Option
            className='filter-select-option truncate'
            value={item.value}
            key={item.value}
          >
            <Tooltip title={item[customKey]} placement={'topLeft'}>
              <span className='w-full truncate'> {item[customKey]}</span>
            </Tooltip>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default GenericFilter;
