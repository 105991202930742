import { Input } from 'antd';
import debounce from '../../../utilities/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../../helper';
import categoryActions from '../../../redux/questionBank/actions';
import { history } from '../../../utilities/configureAxios';

const InterviewQuestionSearch = () => {
  const [value, setValue] = useState<string>('');
  const { q } = getCurrentQueryParams();

  const handleWrapper = (event: any) => {
    setValue(event.target.value);
    debounceOnChange(event);
  };

  const handleSearchChange = (event: any) => {
    const searchValue = event.target.value.trim();
    if (searchValue) {
      history.push(getSearchParamsUrl({ q: searchValue, page: 1 }), {});
    } else {
      history.push(getSearchParamsUrl({ q: '', page: 1 }), {});
    }
  };

  const debounceOnChange = useCallback(debounce(handleSearchChange, 400), []);

  useEffect(() => {
    const query = Array.isArray(q) ? q[0] : q;

    if (typeof query === 'string') {
      setValue(query || '');
    } else {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Input
        className='input-search custom-clear-icon'
        allowClear
        placeholder={'Search Question'}
        onChange={handleWrapper}
        value={value}
        prefix={
          <AiOutlineSearch className='w-10 h-10 g:w-7 lg:h-7 text-light-blue' />
        }
      />
    </>
  );
};

export default InterviewQuestionSearch;
