import { Input } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { connect, useDispatch } from 'react-redux';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../helper';
import referralActions from '../../redux/referrals/actions';
import { history } from '../../utilities/configureAxios';
import { useLocation } from 'react-router-dom';
import debounce from '../../utilities/debounce';
import categoryActions from '../../redux/questionBank/actions';

function InterviewSearch() {
  const [value, setValue] = useState<string>('');
  const dispatch = useDispatch();

  const handleSearchChange = (event: any) => {
    const trimmedValue = event.target.value.trim();

    if (trimmedValue) {
      dispatch({
        type: categoryActions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER,
        payload: {
          page: 1,
          q: trimmedValue
        }
      });
      history.push(getSearchParamsUrl({ q: trimmedValue, page: null }), {});
    } else {
      dispatch({
        type: categoryActions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER,
        payload: {
          page: 1,
          q: ''
        }
      });
      history.push(getSearchParamsUrl({ q: '', page: null }), {});
    }
  };

  const debounceOnChange = useCallback(debounce(handleSearchChange, 400), []);

  const location = useLocation();
  const { q } = getCurrentQueryParams();

  useEffect(() => {
    const query = Array.isArray(q) ? q[0] : q;

    if (typeof query === 'string') {
      setValue(query || '');
      if (query.trim()) {
        dispatch({
          type: categoryActions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER,
          payload: {
            page: 1,
            q: query.trim()
          }
        });
      }
    } else {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWrapper = (event: any) => {
    setValue(event.target.value);
    debounceOnChange(event);
  };

  return (
    <>
      <Input
        className='input-search custom-clear-icon'
        placeholder='Search by Applicant Name, Job Position'
        allowClear
        onChange={handleWrapper}
        value={value}
        prefix={
          <AiOutlineSearch className='w-10 h-10 g:w-7 lg:h-7 text-light-blue' />
        }
      />
    </>
  );
}

export default InterviewSearch;
