import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import AddReferral from '../../components/Modal/AddReferral';
import AllReferralsTable from '../../components/Table/AllReferralsTable';
import { history } from '../../utilities/configureAxios';
import useAllReferrals from './views/useAllReferrals';
import GenericFilter from '../../components/GenericFilter';
import ClearFilters from '../../components/Select/ClearButton';

const AllReferrals = () => {
  const {
    status,
    referralStatus,
    handleFilterChange,
    showClearFilter,
    handleClearFilter
  } = useAllReferrals();
  return (
    <div className='w-full h-screen overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Referrals</title>
      </Helmet>
      <Header />
      <div className='flex items-center justify-between mx-[32px] mb-4'>
        <div className='flex items-center'>
          <GenericFilter
            primaryFilter='All Referrals'
            primaryFitlerValue={null}
            values={referralStatus}
            filterKey='status'
            customKey='name'
            onSelection={handleFilterChange}
            currentValue={status ? Number(status) : null}
            hasPrevioudFilter={showClearFilter()}
          />
          {showClearFilter() && (
            <ClearFilters handleClear={handleClearFilter} />
          )}
        </div>
        <AddReferral heading='Add Referral' />
      </div>
      <AllReferralsTable location={history.location} />
    </div>
  );
};

export default AllReferrals;
