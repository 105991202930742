import { queryStringToJSON } from '../../../utilities/routies';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/hiringTracker/actions';
import moment, { Moment } from 'moment';
import { hiringStatusArray } from '../../../constants/HelperConstants';
import useColumns from './useColumn';
import { RangeValue } from 'rc-picker/lib/interface';
import { history } from '../../../utilities/configureAxios';
import { CustomObject } from '../../../Interfaces/common';
import { formatsDate2, getSearchParamsUrl } from '../../../helper';

const useHiringTracker = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState<{ visible: boolean; slug: string }>({
    visible: false,
    slug: ''
  });
  const { hiringTracker, auth } = useSelector((state: RootStateOrAny) => state);
  const { page, q, created_at_gte, created_at_lte, status } =
    queryStringToJSON(search);
  const handleCalenderChange = (
    date: RangeValue<Moment> | null,
    dateStrings: [string, string]
  ) => {
    const payload: CustomObject = {
      page: 1,
      created_at_gte: date ? dateStrings[0] : null,
      created_at_lte: date ? dateStrings[1] : null
    };

    history.push(
      getSearchParamsUrl(
        payload,
        showClearFilter() ? true : Boolean(date?.length)
      )
    );
  };
  const closeModal = () => {
    setModal({
      visible: false,
      slug: ''
    });
  };
  const handleModal = (slug: string) => {
    setModal({
      visible: true,
      slug
    });
  };
  const { columns } = useColumns({ page, handleModal });

  const showClearFilter = () => {
    return !!status || !!created_at_gte || !!created_at_lte;
  };
  const handleClearFilter = () => {
    history.push(
      getSearchParamsUrl({
        page: 1,
        status: null,
        created_at_gte: null,
        created_at_lte: null
      })
    );
  };
  useEffect(() => {
    if (hiringTracker.exportCSVLoading) {
      dispatch({
        type: actions.SET_STATE,
        payload: { exportCSVLoading: false }
      });
    }
  }, []);
  useEffect(() => {
    dispatch({
      type: actions.GET_ALL_HIRING_TRACKER_DATA,
      payload: {
        page: page || 1,
        q,
        created_at_lte:
          created_at_gte &&
          created_at_lte &&
          moment(created_at_gte).isValid() &&
          moment(created_at_lte).isValid()
            ? created_at_lte
            : moment().format(formatsDate2).toString(),
        created_at_gte:
          created_at_gte &&
          created_at_lte &&
          moment(created_at_gte).isValid() &&
          moment(created_at_lte).isValid()
            ? created_at_gte
            : moment().startOf('month').format(formatsDate2).toString(),
        status: status?.length ? Number(status) : undefined
      }
    });
  }, [created_at_gte, created_at_lte, dispatch, page, q, search, status]);

  const exportCSV = () => {
    dispatch({
      type: actions.EXPORT_CSV,
      payload: {
        q,
        created_at_lte:
          created_at_gte &&
          created_at_lte &&
          moment(created_at_gte).isValid() &&
          moment(created_at_lte).isValid()
            ? created_at_lte
            : moment().format(formatsDate2).toString(),
        created_at_gte:
          created_at_gte &&
          created_at_lte &&
          moment(created_at_gte).isValid() &&
          moment(created_at_lte).isValid()
            ? created_at_gte
            : moment().startOf('month').format(formatsDate2).toString(),
        status: status?.length ? Number(status) : undefined
      }
    });
  };
  return {
    columns,
    hiringData: hiringTracker?.hiringData,
    loading: hiringTracker.loading || auth.loading,
    status,
    showClearFilter,
    handleClearFilter,
    jobStatus: hiringStatusArray,
    modal,
    closeModal,
    handleCalenderChange,
    calnderValue:
      created_at_gte &&
      created_at_lte &&
      moment(created_at_gte).isValid() &&
      moment(created_at_lte).isValid()
        ? ([
            moment(created_at_lte),
            moment(created_at_gte)
          ] as RangeValue<Moment>)
        : ([moment(), moment().startOf('month')] as RangeValue<Moment>),
    exportCSV,
    exportCSVLoading: hiringTracker.exportCSVLoading
  };
};
export default useHiringTracker;
