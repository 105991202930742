import actions from './actions';

const initialState = {
  token: null,
  userId: null,
  name: null,
  email: null,
  profilePic: null,
  role: null,
  job_title: null,
  contact: null,
  status: null,
  loading: false
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}
