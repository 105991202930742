import { connect } from 'react-redux';
import { Input, Menu, Select, Tag, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdOutlineCheck } from 'react-icons/md';
import { RxCrossCircled } from 'react-icons/rx';
import { TiArrowSortedDown } from 'react-icons/ti';

const mapStateToProps = (state: any) => ({
  allHr: state.applications.allHr,
  profilePic: state.auth.profilePic,
  applicationNotes: state.applications.applicationNotes
});

const { TextArea } = Input;

interface IEmptyComment {
  allHr: any;
  profilePic: any;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onPost: (comment: string, taggedUserIds: string[]) => void;
  applicationNotes: any;
  isEdit: boolean;
  commentId: string;
}

interface IIndexArray {
  startIndex: number;
  endIndex: number;
}

const EmptyComment: React.FC<IEmptyComment> = ({
  allHr,
  profilePic,
  onPost,
  setEdit,
  applicationNotes,
  isEdit,
  commentId
}) => {
  const { Option } = Select;
  const [commentText, setCommentText] = useState<string>('');
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [hashedCommentText, setHashedCommentText] = useState<string>('');
  const [showUserList, setShowUserList] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState<Set<string>>(new Set());
  const [currentTag, setCurrentTag] = useState<string | null>(null);
  const [taggedIndexes, setTaggedIndexes] = useState<IIndexArray[]>([]);
  const assignUserBtnRef = useRef<HTMLButtonElement>(null);
  const commentBoxRef = useRef<HTMLTextAreaElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEdit && commentId) {
      const comment = applicationNotes.find(
        (item: any) => item.id === commentId
      );
      setCommentText(comment?.note || '');
      setTaggedUsers(new Set(comment?.tagged_users || []));
    } else {
      setCommentText('');
      setTaggedUsers(new Set());
    }
  }, [applicationNotes, commentId, isEdit]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        assignUserBtnRef.current &&
        !assignUserBtnRef.current.contains(event.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setShowUserList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // useEffect(() => {
  //   if (!commentText && assignedUsers.length > 0) {
  //     setShowUserList(false);
  // setAssignedUsers([]);
  // setTaggedUsers(new Set());
  //   }
  // }, [commentText]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    // let diffAt = -1;
    // let diffCount = 0;
    // for (let i = 0; i < commentText.length; i++) {
    //   if (value[i] !== commentText[i]) {
    //     diffAt = i;
    //     let j = 0;
    //     while (j < commentText.length) {
    //       if (value[j] !== commentText[j]) {
    //         diffCount++;
    //       }
    //       j++;
    //     }
    //     break;
    //   }
    // }
    // if (diffAt !== -1) {
    //   const filteredIndices = taggedIndexes.filter((tag) => {
    //     if (diffAt >= tag.startIndex && diffAt <= tag.endIndex) {
    //       return false;
    //     }
    //     return true;
    //   });
    //   taggedIndexes.forEach((tag) => {
    //     if (tag.startIndex > diffAt) {
    //       tag.startIndex = tag.startIndex - (commentText.length - value.length);
    //       tag.endIndex = tag.endIndex - (commentText.length - value.length);
    //     }
    //   });

    //   setTaggedIndexes(filteredIndices);
    // }
    setCommentText(value);

    // const mentionRegex = /(^|\s)@([a-zA-Z0-9_\.]+)$/;
    // const mentionMatch = mentionRegex.exec(value);

    // if (mentionMatch) {
    //   const [_, precedingChar, username] = mentionMatch;
    //   if (precedingChar.trim() === '') {
    //     setCurrentTag(username);
    //     setShowUserList(true);
    //   } else {
    //     setShowUserList(false);
    //     setCurrentTag(null);
    //   }
    // } else {
    //   setShowUserList(false);
    //   setCurrentTag(null);
    // }
  };

  // const formatMention = (text: string, username: string) => {
  //   return text.replace(`@${currentTag}`, `@${username} `);
  // };

  // function findStartAndEnd(str: string, substr: string) {
  //   let startIndex = 0;
  //   startIndex = str.lastIndexOf(substr);
  //   return {
  //     startIndex,
  //     endIndex: startIndex + substr.length - 1
  //   };
  // }
  const handleUserClick = (user: any) => {
    if (assignedUsers.includes(user.key)) {
      setTaggedUsers((prev) => {
        const newSet = new Set(prev);
        newSet.delete(user.key);
        return newSet;
      });
      setAssignedUsers((prev) => prev.filter((id) => id !== user.key));
    } else {
      setTaggedUsers((prev) => {
        const newSet = new Set(prev);
        newSet.add(user.key);
        return newSet;
      });
      setAssignedUsers((prev) => [...prev, user.key]);
    }

    // const formattedName = user.name.replace(/\s+/g, '');
    // const updatedCommentText = commentText.replace(
    //   `@${currentTag}`,
    //   `@${formattedName} `
    // );
    // setCommentText(updatedCommentText);

    // const indexes = findStartAndEnd(updatedCommentText, `@${formattedName}`);
    // setTaggedIndexes((prevIndices) => [...prevIndices, indexes]);

    // setHashedCommentText(formatMention(hashedCommentText, formattedName));
    // setShowUserList(false);
    // setCurrentTag(null);
    // commentBoxRef.current?.focus();
  };

  // const replaceTaggedSubstrings = (
  //   text: string,
  //   taggedIndexes: IIndexArray[]
  // ): string => {
  //   const sortedIndexes = [...taggedIndexes].sort(
  //     (a, b) => a.startIndex - b.startIndex
  //   );
  //   let newText = '';
  //   let lastEndIndex = 0;

  //   sortedIndexes.forEach(({ startIndex, endIndex }) => {
  //     newText += text.slice(lastEndIndex, startIndex);
  //     newText += `<tagged>${text.slice(startIndex, endIndex + 1)}</tagged>`;
  //     lastEndIndex = endIndex + 1;
  //   });

  //   newText += text.slice(lastEndIndex);

  //   return newText;
  // };

  const handlePostClick = () => {
    // const updatedCommentText = replaceTaggedSubstrings(
    //   commentText,
    //   taggedIndexes
    // );
    // setTaggedIndexes([]);

    if (commentText !== '') {
      onPost(commentText, Array.from(taggedUsers));
      setShowUserList(false);
      setAssignedUsers([]);
      setTaggedUsers(new Set());
    }
  };

  const handleUserAssignClick = () => {
    setShowUserList((prev) => !prev);
    // setTaggedUsers(new Set());
    // setAssignedUsers([]);
  };

  const handleAssignedUsersChange = (value: string[]) => {
    // setTaggedUsers((prev) => {
    //   const newSet = new Set(prev);
    //   newSet.add(value);
    //   return newSet;
    // });
    // setTaggedUsers(value);
    const uniqueValues = Array.from(new Set(value));
    setTaggedUsers(new Set(uniqueValues));
    setAssignedUsers(value);
  };

  const handleUserRemove = (_: any, key: string) => {
    setTaggedUsers((prev) => {
      const newSet = new Set(prev);
      newSet.delete(key);
      return newSet;
    });
    setAssignedUsers((prev) => prev.filter((id) => id !== key));
  };

  const menuItems = allHr
    ? allHr
        .filter((user: any) => user && user.id)
        .map((user: any) => ({
          key: user.id,
          label: (
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-x-1'>
                <div
                  style={{
                    width: '30px',
                    height: '30px'
                  }}
                >
                  <img
                    src={user?.profilePic || '/user-pic.svg'}
                    alt=''
                    className='object-cover rounded-full'
                    referrerPolicy='no-referrer'
                  />
                </div>
                <span className='ml-2'>{user.name}</span>
              </div>
              {assignedUsers && assignedUsers.includes(user.id) && (
                <MdOutlineCheck className='inline-block text-light-blue' />
              )}
            </div>
          )
        }))
    : [];

  return (
    <div className='mb-2'>
      {/* {showUserList && allHr && allHr.length > 0 && (
        <Menu
          className='!bg-[#F4F5F7] !ml-[58px]'
          style={{
            position: 'absolute',
            bottom: '50px',
            zIndex: 1000,
            width: '200px',
            maxHeight: '200px',
            overflowY: 'auto'
          }}
          tabIndex={0}
        >
          {allHr
            .filter((user: any) =>
              user?.name
                ?.toLowerCase()
                .includes((currentTag || '')?.toLowerCase())
            )
            .map((user: any) => {
              const formattedName = user.name.replace(/\s+/g, '');
              return (
                <Menu.Item
                  className='hover:bg-[#DEE9FC]'
                  key={user.id}
                  onClick={() => handleUserClick(user)}
                >
                  {formattedName}
                </Menu.Item>
              );
            })}
        </Menu>
      )} */}
      <div className='relative' ref={menuRef}>
        {showUserList && (
          <>
            <Menu
              className='rounded-lg !ml-[58px] !border-0 !shadow-lg !m-0'
              style={{
                position: 'absolute',
                bottom: '0px',
                right: '0',
                zIndex: 1000,
                width: 'auto',
                minWidth: '250px',
                maxHeight: '250px',
                overflowY: 'auto'
              }}
            >
              <div className='flex items-center justify-between px-4 py-4 sticky top-0 left-0 z-10 bg-white'>
                <span className='font-semibold'>Select Users</span>
                <span
                  className='w-5 h-5 flex items-center justify-center bg-gray-lighter rounded-full cursor-pointer'
                  onClick={() => setShowUserList(false)}
                >
                  <AiOutlineClose className='w-3 h-3 text-gray-light' />
                </span>
              </div>
              {menuItems.map((item: any) => (
                <Menu.Item
                  className={`${
                    assignedUsers.includes(item.key) ? 'bg-[#DEE9FC]' : ''
                  } hover:bg-[#DEE9FC]`}
                  onClick={handleUserClick}
                  key={item.key}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
            <TiArrowSortedDown className='absolute right-9 text-black text-[23px] -top-[8px]' />
          </>
        )}
      </div>
      <div className='flex'>
        <div className='mr-4'>
          <div
            style={{
              width: '40px',
              height: '40px'
            }}
          >
            <img
              src={profilePic || '/user-pic.svg'}
              alt=''
              className='object-cover cursor-pointer rounded-full'
            />
          </div>
        </div>
        <div className='w-full border-solid border border-[#BDBBC766] rounded px-2 flex bg-[#F4F7FD66]'>
          <TextArea
            ref={commentBoxRef}
            placeholder='Write a Comment'
            rows={1}
            name='comment'
            value={commentText}
            onChange={handleCommentChange}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (event.shiftKey) return;
                else {
                  event.preventDefault();
                  handlePostClick();
                }
              }
            }}
            className='emptyCommentInput'
          />
          <div className='flex gap-x-2 items-center justify-end'>
            <button
              className={`border-none cursor-pointer`}
              onClick={handleUserAssignClick}
              ref={assignUserBtnRef}
            >
              <Tooltip placement='bottom' title='Assign Users'>
                <FaRegUserCircle
                  color={`${assignedUsers.length > 0 ? '#2D94F3' : '#c7c7c7'}`}
                  fontSize='20px'
                />
              </Tooltip>
            </button>
            <button
              className={`border-none ${
                !commentText ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              disabled={!commentText}
              onClick={handlePostClick}
            >
              <FiSend
                color={`${!commentText ? '#c7c7c7' : '#2D94F3'}`}
                fontSize='20px'
              />
            </button>
            {isEdit && (
              <AiOutlineCloseCircle
                color='#2D94F3'
                className='ml-2'
                fontSize='20px'
                cursor='pointer'
                onClick={() => {
                  setCommentText('');
                  setHashedCommentText('');
                  setEdit(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(EmptyComment);
