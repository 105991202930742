import { useEffect, useRef, useState } from 'react';
import { history } from '../../../utilities/configureAxios';
import { getGenericErrorMessage, getSearchParamsUrl } from '../../../helper';
import useDebounce from '../../../hooks/useDebounce';
import { isString } from 'lodash';

// dependentApiCall:- String for the feilds where there is any dependency for previous feild.
// edit:- is user is in edit form mode
// reset:- a boolean to know if filter/form has been reset and need to clear the autocomplete.
// extraPayload:- payload to append to current payload of the api service like active:true etc.
interface TautoComplete {
  setselectedValue?: (option: any) => void;
  defaultValue?: string;
  edit?: Boolean;
  dependentApiCall?: string;
  key?: string;
  reset?: boolean;
  apiService: (payload: any) => Promise<unknown>;
  extraPayload?: { [key: string]: string | number | null | boolean };
  isPayload?: Boolean;
  idType?: string;
}

export const useAutoComplete = ({
  setselectedValue,
  key,
  apiService,
  defaultValue,
  edit,
  extraPayload,
  dependentApiCall,
  reset,
  isPayload,
  idType
}: TautoComplete) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const searchActive = useRef<string | boolean>(edit ? 'initial' : false);
  //splitting the label and id on basis of ~ and setting label as default value
  useEffect(() => {
    setSearchValue(
      (defaultValue?.includes('~') && defaultValue?.split('~')?.length
        ? defaultValue?.split('~')[1]
        : defaultValue) || ''
    );
  }, [defaultValue]);
  const debounceSearch = useDebounce(
    searchValue,
    isString(searchActive.current) ? 0 : 300
  );
  //Calling api from the function
  const apiCallRequest = () => {
    // This condition is for when user is editing the form.
    // Checking for the length of the search and if saearch active.
    // if there is any dependent api call so that call goes when values change
    if (
      (edit &&
        !debounceSearch?.length &&
        !dependentApiCall?.length &&
        !isString(searchActive.current) &&
        !searchActive.current) ||
      (!dependentApiCall?.length && dependentApiCall !== undefined)
    ) {
      return;
    }
    setSearchResultLoading(true);
    let q: string | undefined = debounceSearch?.length
      ? isString(searchActive.current) && debounceSearch?.includes('~')
        ? debounceSearch?.split('~')[1]
        : debounceSearch
      : undefined;

    if (
      !isString(searchActive?.current) &&
      searchActive?.current &&
      !debounceSearch?.length
    ) {
      searchActive.current = false;
    }
    apiService(
      isPayload
        ? {
            page: 1,
            q: q,
            ...extraPayload
          }
        : {
            params: {
              page: 1,
              q: q,
              ...extraPayload
            }
          }
    )
      .then((res: any) => {
        setSearchResultLoading(false);
        if (res?.status === 200) {
          if (edit && isString(searchActive?.current)) {
            searchActive.current = false;
          }
          setSearchResult(res?.data?.data);
        } else {
          setSearchResult([]);
          getGenericErrorMessage(res);
        }
      })
      .catch((err) => {
        setSearchResult([]);
        getGenericErrorMessage(err);
        setSearchResultLoading(false);
      });
  };
  useEffect(() => {
    if (debounceSearch !== undefined) {
      apiCallRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, dependentApiCall, reset]);
  const handleSearchChange = (e: string) => {
    searchActive.current = true;
    if (e?.length === 0) {
      if (setselectedValue) {
        setselectedValue('');
      }
    }
    setSearchValue(e);
  };

  const handleSelect = (value: string, option: any) => {
    searchActive.current = false;
    if (idType === 'job_id') {
      if (String(option?.data?.id) !== defaultValue?.split('~')[0]) {
        if (setselectedValue) {
          setselectedValue(option?.data);
        } else {
          let payload: any = { page: 1 };
          payload[key!] = option?.data?.id;
          history.push(getSearchParamsUrl(payload), {});
        }
        setSearchValue(value || '');
      }
    } else {
      if (String(option?.data?.job_ref_id) !== defaultValue?.split('~')[0]) {
        if (setselectedValue) {
          setselectedValue(option?.data);
        } else {
          let payload: any = { page: 1 };
          payload[key!] = option?.data?.id;
          history.push(getSearchParamsUrl(payload), {});
        }
        setSearchValue(value || '');
      }
    }
  };
  const clearSearchValue = () => {
    if (setselectedValue) {
      setselectedValue('');
    }
    handleSearchChange('');
  };
  return {
    handleSearchChange,
    handleSelect,
    clearSearchValue,
    searchValue,
    searchResultLoading,
    searchResult,
    searchActive
  };
};
