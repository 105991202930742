import { Input, Modal, Select } from 'antd';
import { isString } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import PrimaryButton from '../../Buttons/Primary';
import { connect, useDispatch } from 'react-redux';
import referralActions from '../../../redux/referrals/actions';
import jobActions from '../../../redux/jobs/actions';
import FileUpload from '../../FileUpload/ReferralFileUpload';
import { USER_ROLE, referralType } from '../../../constants/HelperConstants';
import AutoComplete from '../../AutoComplete/AutoComplete';
import { useLocation } from 'react-router-dom';
import { queryStringToJSON } from '../../../utilities/routies';
import JobsServices from '../../../services/jobs';
import JobOptionBox from '../../JobOptionBox/JobOptionBox';
import PhoneNumberInput from '../../PhoneNumberInput';

const mapStateToProps = (state: any) => ({
  jobsForAddApplicant: state.jobs.jobsForAddApplicant,
  role: state.auth.role
});
interface IJobSelect {
  jobsForAddApplicant: any;
  role: any;
  heading: string;
}

const AddReferral: React.FC<IJobSelect> = ({
  jobsForAddApplicant,
  heading,
  role
}) => {
  const [idTitle, setIdTitle] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState<any>();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [referralID, setReferralID] = useState('');
  const [referralTitle, setReferralTitle] = useState<any>();
  const [valid, setValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({
    // mobileNumber: '',
    email: ''
  });
  const [referredBy, setReferredBy] = useState<any>('');
  const { Option } = Select;
  const { search } = useLocation();
  const { q, status, job_id, label } = queryStringToJSON(search);

  useEffect(() => {
    if (
      jobTitle?.trim().length > 0 &&
      fullName.trim().length > 0 &&
      email.trim().length > 0 &&
      mobileNumber?.toString().length! > 0 &&
      fileName.trim().length > 0 &&
      referralTitle?.trim().length > 0
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [fullName, email, mobileNumber, fileName, referralTitle]);
  useEffect(() => {
    dispatch({ type: jobActions.GET_ALL_JOBS_FOR_ADD_APPLICANT });
  }, []);

  const onSave = () => {
    const errorsObject = {
      // mobileNumber:
      //   mobileNumber?.toString().length !== 10 ? 'Invalid Mobile number' : '',
      email: validateEmail(email) ? '' : 'Invalid email'
    };
    setErrors(errorsObject);
    if (!errorsObject.email) {
      const body = {
        job_id: jobId,
        full_name: fullName,
        contact: Number(mobileNumber),
        email: email,
        resume: fileName,
        resume_mime_type: fileType,
        type: referralID,
        referred_by: referredBy
      };
      dispatch({
        type:
          role === USER_ROLE.EMPLOYEE || role === USER_ROLE.INTERVIEWER
            ? referralActions.ADD_EMPLOYEE_REFERRAL
            : referralActions.ADD_HR_REFERRAL,
        payload: {
          body: body
        }
      });
      setJobTitle(null);
      setEmail('');
      setFullName('');
      setMobileNumber('');
      setIsOpen(false);
      setReferredBy('');
      setReferralID('');
      setReferralTitle('');
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    if (email !== '' || fullName !== '') {
      const res = window.confirm('Are you sure ?');
      if (res) {
        setIsOpen(false);
        setEmail('');
        setFullName('');
        setMobileNumber('');
        setJobTitle(null);
        setFileName('');
        setReferralTitle(null);
        setReferredBy('');
        setErrors({
          // mobileNumber: '',
          email: ''
        });
      }
    } else {
      setIsOpen(false);
      setReferredBy('');
    }
  };
  const dispatch = useDispatch();

  const handleChange = (value: any, key: any) => {
    let [title, id] = getJobTitle(value.toString());
    setJobId(id);
    setJobTitle(value);
  };
  const handleRefType = (value: any, key: any) => {
    setReferralID(value);
    setReferralTitle(key.children);
  };
  const getJobTitle = (jobId: any) => {
    let jobTitle = jobsForAddApplicant?.data?.filter(
      (job: any) => jobId === job?.id
    );
    let title = jobTitle[0]?.job_title;
    let id = jobTitle[0]?.job_ref_id;
    return [title, id];
  };
  const handleFilterChange = (data: any) => {
    if (!data) {
      setJobId('');
      setJobTitle('');
    } else {
      setJobId(data?.id);
      setJobTitle(data?.job_title);
    }
  };
  const customJSX = (item: any): ReactNode => {
    return JobOptionBox({ data: item });
  };
  const jobService = new JobsServices();

  function validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <PrimaryButton text='Add Referral' onClick={showModal} />
      <Modal
        className='edit-task-modal'
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
        width='1099px'
      >
        <div className='bg-[#FAFDFF]'>
          <div className='flex justify-between '>
            <h3 className='text-xl text-center font-semibold text-blue-zodiac'>
              {heading}
            </h3>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
              onClick={handleCancel}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>

          <div className='flex flex-col space-y-[30px]'>
            <div className='grid grid-cols-2'>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  {'Job Title'}
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <AutoComplete
                  apiService={jobService.GetAllJobsForFilter}
                  key='job_id'
                  placeholder='All Jobs'
                  isPayload
                  setselectedValue={(data: any) => {
                    if (!data) {
                      setJobId('');
                      setJobTitle('');
                    } else {
                      setJobId(String(data?.job_ref_id));
                      setJobTitle(data?.job_title);
                    }
                  }}
                  customJSX={customJSX}
                  defaultValue={
                    String(jobId)?.length &&
                    isString(String(jobId)) &&
                    jobTitle?.length
                      ? `${jobId}~${jobTitle}`
                      : ''
                  }
                  // reset={reset}
                  className='edit-input text-base capitalize'
                />
              </div>
              <div className='ml-2'>
                <p className='applicant-modal-label'>
                  {'Full Name'}
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  className='edit-input text-base capitalize'
                  value={fullName}
                  onChange={(e: any) => setFullName(e.target.value)}
                  placeholder='Enter full name'
                />
              </div>
            </div>
            <div className='flex justify-between space-x-3'>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  {'Email'}
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  className='edit-input text-base'
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  placeholder='Enter email'
                />
                {errors.email !== '' && (
                  <div className='error-message absolute bottom-[-20px]'>
                    {errors.email}
                  </div>
                )}
              </div>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  {'Mobile Number'}
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <PhoneNumberInput
                  className='edit-input text-base capitalize'
                  value={mobileNumber ?? ''}
                  onChange={(value: any) => setMobileNumber(value)}
                  placeholder='Enter Mobile Number'
                />
                {/* {errors.mobileNumber !== '' && (
                  <div className='error-message absolute bottom-[-20px]'>
                    {errors.mobileNumber}
                  </div>
                )} */}
              </div>
            </div>
            <div>
              <p className='applicant-modal-label'>
                {'Attach CV'}
                <span className='text-lg text-star-red pl-1'>*</span>
              </p>
              <div className='cursor-pointer'>
                <FileUpload
                  setFileName={setFileName}
                  fileType={fileType}
                  setFileType={setFileType}
                />
              </div>
            </div>
            <div className='flex-grow'>
              <p className='applicant-modal-label'>
                {'How do you know this candidate'}
                <span className='text-lg text-star-red pl-1'>*</span>
              </p>
              <Select
                className='w-full filter-select edit-input'
                value={referralTitle ? referralTitle : 'Select an option'}
                onChange={handleRefType}
                placeholder='Select an option'
              >
                {referralType.map((type: any, i: any) => (
                  <Option
                    className='text-base filter-select-option'
                    value={type.id}
                    key={type.id}
                  >
                    {type.title}
                  </Option>
                ))}
              </Select>
            </div>
            {/* ===Referred By, for manual entry fo names=== */}
            {role === USER_ROLE.ORGNIZATION_ADMIN ||
            role === USER_ROLE.HR ||
            role === USER_ROLE.HR_LEAD ? (
              <div className='flex-grow'>
                <p className='applicant-modal-label'>Referred By</p>
                <Input
                  className='edit-input text-base'
                  value={referredBy}
                  onChange={(e: any) => setReferredBy(e.target.value)}
                  placeholder='Enter a name'
                />
              </div>
            ) : null}
            {/* ===Referred By, for manual entry fo names=== */}
            <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
              <PrimaryButton disabled={!valid} text='Save' onClick={onSave} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps)(AddReferral);
