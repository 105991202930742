import { all, takeEvery, put, select } from 'redux-saga/effects';
import { doApiCall, getCurrentQueryParams } from '../../helper';
import actions from './actions';
import ApplicationServices from '../../services/applications';
import { notification } from 'antd';
import { Store } from '../store';
import { history } from '../../utilities/configureAxios';
import referralActions from '../referrals/actions';

const service = new ApplicationServices();

export function* WATCH_GET_ALL_APPLICATIONS(action) {
  const response = yield doApiCall(service.GetAllApplications, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { applications: response }
    });
  }
}

export function* WATCH_GET_DUPLICATE_APPLICATIONS(action) {
  const response = yield doApiCall(
    service.GetDuplicateApplications,
    action.payload
  );

  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { duplicateApplication: response.data }
    });
  }
}

export function* WATCH_GET_ALL_HRS(action) {
  const response = yield doApiCall(service.GetAllHRs, action.payload);
  if (response?.success) {
    yield put({
      type: actions.GET_ALL_HRS_SUCCESS,
      payload: { applications: response?.data }
    });
  }
}

export function* WATCH_GET_ALL_TASK_LIST(action) {
  const response = yield doApiCall(service.GetAllTaskList, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { taskList: response?.data }
    });
  }
}
export function* WATCH_ALLOT_TASK(action) {
  const response = yield doApiCall(service.AllotTask, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Task Sent'
    });
    yield put({
      type: actions.GET_ALL_TASKS_LIST,
      payload: {
        ref_id: action.payload.ref_id
      }
    });
  }
}
export function* WATCH_UPDATE_ALLOTED_TASK(action) {
  const response = yield doApiCall(service.UpdateAllotedTask, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Task Updated'
    });
    yield put({
      type: actions.GET_ALL_TASKS_LIST,
      payload: {
        ref_id: action.payload.ref_id
      }
    });
  }
}
export function* WATCH_ADD_APPLICANT(action) {
  const response = yield doApiCall(service.AddApplicant, action.payload);
  if (response?.success === true) {
    const { applications } = Store.getState();
    const { flag, page } = action.payload;
    if (flag === 'get_hr_referal') {
      yield put({
        type: referralActions.GET_ALL_HR_REFERRALS,
        payload: { page }
      });
    } else {
      yield put({
        type: actions.GET_ALL_APPLICATIONS,
        payload: { page: 1, ...applications.filters }
      });
    }
    notification.success({
      message: 'Applicant Added Successfully'
    });
  }
}

export function* WATCH_GET_SINGLE_APPLICATION(action) {
  const response = yield doApiCall(service.GetSingleApplication, {
    ref_id: action.payload.ref_id
  });
  if (response?.success) {
    const { data } = response;
    yield put({
      type: actions.SET_STATE,
      payload: { singleApplication: data }
    });
  } else {
    history.push(`/404`);
  }
}

export function* WATCH_UPDATE_STATUS_OF_APPLICATION(action) {
  const response = yield doApiCall(
    service.UpdateSingleApplication,
    action.payload
  );
  if (response?.success) {
    Store.dispatch({
      type: actions.UPDATE_CURRENT_STATUS,
      payload: action?.payload?.body?.status
    });
    if (!action.payload.custom) {
      Store.dispatch({
        type: actions.GET_SINGLE_APPLICATION,
        payload: { ref_id: action?.payload?.ref_id, history }
      });
    }
    Store.dispatch({
      type: actions.TOGGLE_UPDATE_STATUS_MODAL,
      payload: false
    });
    if (action.payload.cb) {
      action.payload.cb();
    }
    notification.success({
      message: 'Application status updated successfully'
    });
    if (!action.payload.custom) {
      const {
        status,
        page,
        q,
        job_id,
        graduation_gte,
        graduation_lte,
        ctc_gte,
        ctc_lte,
        cool_off_status,
        tags,
        sort,
        order
      } = getCurrentQueryParams();
      yield put({
        type: actions.GET_ALL_APPLICATIONS,
        payload: {
          page: page ?? 1,
          status: status,
          job_id: job_id,
          graduation_gte: graduation_gte,
          graduation_lte: graduation_lte,
          ctc_gte: ctc_gte,
          ctc_lte: ctc_lte,
          q: q,
          cool_off_status: cool_off_status,
          tags,
          sort,
          order
        }
      });
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        formErrors: { aliasTo: response?.errors || response?.message || '' }
      }
    });
  }
}

export function* WATCH_CREATE_APPLICATION(action) {
  const response = yield doApiCall(service.CreateApplication, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Application created successfully'
    });
  }
}

export function* WATCH_CREATE_APPLICATION_NOTE(action) {
  const response = yield doApiCall(
    service.CreateApplicationNote,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.ADD_COMMENT,
      payload: response.data
    });
    notification.success({
      message: 'Comment Added Successfully'
    });
  }
}

export function* WATCH_SCHEDULE_INTERVIEW(action) {
  const response = yield doApiCall(
    service.ScheduleInterview,
    action.payload,
    actions.SET_STATE
  );

  if (response?.success) {
    yield put({
      type: actions.ADD_SCHEDULE_INTERVIEW,
      payload: {
        ...action.payload
      }
    });
    yield put({
      type: actions.GET_INTERVIEW_SCHEDULE,
      payload: {
        ref_id: action.payload.ref_id
      }
    });
    yield put({
      type: actions.SET_STATE,
      payload: { postInterviewResponse: response.data }
    });
    yield put({
      type: actions.TOGGLE_MODAL,
      payload: false
    });

    notification.success({
      message: 'Interview scheduled successfully'
    });
  }
}

export function* WATCH_GET_INTERVIEW_SCHEDULE(action) {
  const response = yield doApiCall(
    service.GetInterview,
    action.payload,
    actions.SET_INTERVIEW_LOADING
  );
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { interviewDetails: response.data, interviewLoading: false }
    });
  }
}

export function* WATCH_GET_APPLICATION_NOTE(action) {
  const response = yield doApiCall(service.GetApplicationNote, action.payload);
  if (response?.success) {
    const { data } = response;
    yield put({
      type: actions.SET_STATE,
      payload: { applicationNotes: data }
    });
  }
}

export function* WATCH_GET_NEXT_APPLICATION(action) {
  const response = yield doApiCall(
    service.GetNextSingleApplication,
    action.payload
  );
  if (response?.success) {
    const { data } = response;
    yield put({
      type: actions.SET_STATE,
      payload: { nextApplication: data }
    });
  }
}

export function* WATCH_GET_PREV_APPLICATION(action) {
  const response = yield doApiCall(
    service.GetPrevSingleApplication,
    action.payload
  );
  if (response?.success) {
    const { data } = response;
    yield put({
      type: actions.SET_STATE,
      payload: { prevApplication: data }
    });
  }
}

export function* WATCH_DELETE_INTERVIEW(action) {
  const response = yield doApiCall(service.DeleteInterview, action.payload);

  if (response?.success) {
    yield put({
      type: actions.GET_INTERVIEW_SCHEDULE,
      payload: {
        ref_id: action.payload.ref_id
      }
    });

    yield put({
      type: actions.TOGGLE_INTERVIEW_MODAL,
      payload: false
    });
    notification.success({
      message: 'Interview cancelled successfully'
    });
  }
}
export function* WATCH_DELETE_COMMENT(action) {
  const response = yield doApiCall(service.DeleteComment, action.payload);

  if (response?.success) {
    yield put({
      type: actions.GET_APPLICATION_NOTE,
      payload: {
        ref_id: action.payload.ref_id
      }
    });

    yield put({
      type: actions.TOGGLE_COMMENT_MODAL,
      payload: false
    });
    notification.success({
      message: 'Comment deleted successfully'
    });
  }
}
export function* WATCH_UPDATE_INTERVIEW(action) {
  const response = yield doApiCall(service.updateInterview, action.payload);

  if (response?.success) {
    yield put({
      type: actions.TOGGLE_MODAL,
      payload: false
    });
    yield put({
      type: actions.TOGGLE_MODAL,
      payload: false
    });
    yield put({
      type: actions.GET_INTERVIEW_SCHEDULE,
      payload: {
        ref_id: action.payload.ref_id
      }
    });
    notification.success({
      message: 'Interview updated successfully'
    });
  }
}

export function* WATCH_POST_INTERVIEW_CONCENT(action) {
  const response = yield doApiCall(
    service.PostInterviewConcent,
    action.payload
  );
  if (response?.success) {
    const localObj = {
      res: action.payload.mr,
      timestamp: new Date()
    };
    localStorage.setItem(action.payload.uuid, JSON.stringify(localObj));
    yield put({
      type: actions.SET_STATE,
      payload: { concentLoading: false }
    });
  }
}

export function* WATCH_UPDATE_COMMENT(action) {
  const response = yield doApiCall(service.UpdateComment, action.payload);
  if (response?.success) {
    yield put({
      type: actions.GET_APPLICATION_NOTE,
      payload: {
        ref_id: action.payload.ref_id
      }
    });
    yield put({
      type: actions.TOGGLE_COMMENT_MODAL,
      payload: false
    });
    notification.success({
      message: 'Comment updated successfully'
    });
  }
}

export function* WATCH_TOGGLE_LOADING(action) {
  const { applicationLoading } = yield select((state) => state.applications);
  if (applicationLoading && action.payload) {
    return;
  }
  yield put({
    type: actions.SET_STATE,
    payload: { applicationLoading: action.payload }
  });
}

export function* WATCH_POST_INTERVIEW_QUESTION_SET(action) {
  const response = yield doApiCall(
    service.PostInterviewQuestionSet,
    action.payload
  );
  if (response?.success) {
    notification.success({ message: 'Question set generated successfully!' });
    // yield put({
    //     type: actions.GET_ALL_QUESTION_MAPPINGS,
    //     payload: { page: 1 },
    // });
    yield put({
      type: actions.GET_INTERVIEW_SCHEDULE,
      payload: {
        ref_id: action.payload.ref_id
      }
    });
  }
}

export function* WATCH_GENERATE_A_QUESTION_SET(action) {
  const response = yield doApiCall(
    service.PostGenerateAQuestionSet,
    action.payload
  );
  yield put({
    type: actions.GENERATE_A_QUESTION_SET_GENERIC,
    payload: { generateAQuestionSetGeneric: response }
  });
  if (response?.success) {
    notification.success({ message: 'Question set generated successfully!' });
    yield put({
      type: actions.GENERATE_A_QUESTION_SET_SUCCESS,
      payload: { generateAQuestionSet: response }
    });
  }
}

export function* WATCH_GET_INTERVIEW_HISTORY(action) {
  const response = yield doApiCall(service.GetInterviewHistory, action.payload);
  if (response?.success) {
    yield put({
      type: actions.GET_INTERVIEW_HISTORY_SUCCESS,
      payload: { interviewHistory: response }
    });
  }
}

export function* WATCH_GET_ALL_TAGS(action) {
  const response = yield doApiCall(service.GetAllTags, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_ALL_TAGS,
      payload: { data: response?.data }
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.TOGGLE_LOADING, WATCH_TOGGLE_LOADING)]);
  yield all([
    takeEvery(actions.GET_INTERVIEW_HISTORY, WATCH_GET_INTERVIEW_HISTORY)
  ]);
  yield all([takeEvery(actions.ADD_APPLICANT, WATCH_ADD_APPLICANT)]);
  yield all([takeEvery(actions.UPDATE_COMMENT, WATCH_UPDATE_COMMENT)]);
  yield all([takeEvery(actions.GET_ALL_TASKS_LIST, WATCH_GET_ALL_TASK_LIST)]);
  yield all([takeEvery(actions.ALLOT_TASK, WATCH_ALLOT_TASK)]);
  yield all([
    takeEvery(actions.UPDATE_ALLOTED_TASK, WATCH_UPDATE_ALLOTED_TASK)
  ]);

  yield all([
    takeEvery(actions.GET_ALL_APPLICATIONS, WATCH_GET_ALL_APPLICATIONS)
  ]);
  yield all([takeEvery(actions.GET_ALL_HRS, WATCH_GET_ALL_HRS)]);

  yield all([
    takeEvery(actions.GET_SINGLE_APPLICATION, WATCH_GET_SINGLE_APPLICATION)
  ]);
  yield all([
    takeEvery(
      actions.UPDATE_STATUS_OF_APPLICATION,
      WATCH_UPDATE_STATUS_OF_APPLICATION
    )
  ]);
  yield all([takeEvery(actions.CREATE_APPLICATION, WATCH_CREATE_APPLICATION)]);
  yield all([
    takeEvery(actions.CREATE_APPLICATION_NOTE, WATCH_CREATE_APPLICATION_NOTE)
  ]);
  yield all([
    takeEvery(actions.GET_APPLICATION_NOTE, WATCH_GET_APPLICATION_NOTE)
  ]);
  yield all([
    takeEvery(actions.GET_NEXT_APPLICATION, WATCH_GET_NEXT_APPLICATION)
  ]);
  yield all([
    takeEvery(actions.GET_PREV_APPLICATION, WATCH_GET_PREV_APPLICATION)
  ]);
  yield all([
    takeEvery(actions.GET_INTERVIEW_SCHEDULE, WATCH_GET_INTERVIEW_SCHEDULE)
  ]);
  yield all([takeEvery(actions.SCHEDULE_INTERVIEW, WATCH_SCHEDULE_INTERVIEW)]);
  yield all([takeEvery(actions.DELETE_INTERVIEW, WATCH_DELETE_INTERVIEW)]);
  yield all([
    takeEvery(actions.POST_INTERVIEW_CONCENT, WATCH_POST_INTERVIEW_CONCENT)
  ]);

  yield all([takeEvery(actions.DELETE_COMMENT, WATCH_DELETE_COMMENT)]);
  yield all([
    takeEvery(actions.UPDATE_SCHEDULED_INTERVIEW, WATCH_UPDATE_INTERVIEW)
  ]);
  yield all([
    takeEvery(
      actions.POST_INTERVIEW_QUESTION_SET,
      WATCH_POST_INTERVIEW_QUESTION_SET
    )
  ]);
  yield all([
    takeEvery(actions.GENERATE_A_QUESTION_SET, WATCH_GENERATE_A_QUESTION_SET)
  ]);
  yield all([
    takeEvery(
      actions.GET_DUPLICATE_APPLICATION_MODAL,
      WATCH_GET_DUPLICATE_APPLICATIONS
    )
  ]);
  yield all([takeEvery(actions.GET_ALL_TAGS, WATCH_GET_ALL_TAGS)]);
}
