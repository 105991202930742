import React, { useState, useEffect, useRef } from 'react';
import { Modal, Input, Tooltip, Select, Tag, Menu } from 'antd';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import moment from 'moment';
import { formatsDateTime } from '../../../helper';
import { FaRegUserCircle } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineCheck } from 'react-icons/md';
import { TiArrowSortedDown } from 'react-icons/ti';

const mapStateToProps = (state: any) => ({
  allHr: state.applications.allHr,
  open: state.applications.openViewCommentModal
});

interface IComment {
  note: string;
  user: any;
  created_at: string;
  id: string;
  tagged_users: string[];
}

interface IViewCommentModal {
  allHr: any;
  open: boolean;
  comment: IComment;
  isSameUser: boolean;
  onClose: () => void;
  onEdit: (newNote: string, taggedUserIds: string[]) => void;
}

const ViewComment: React.FC<IViewCommentModal> = ({
  allHr,
  open,
  isSameUser,
  onClose,
  comment,
  onEdit
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(comment?.note || '');
  const [showUserList, setShowUserList] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [taggedUsers, setTaggedUsers] = useState<Set<string>>(new Set());
  const [initialAssignedUsers, setInitialAssignedUsers] = useState<string[]>(
    []
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const assignUserBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setEditedNote(comment?.note || '');
    setAssignedUsers(comment?.tagged_users || []);
    setTaggedUsers(new Set(comment?.tagged_users || []));
    setInitialAssignedUsers(comment?.tagged_users || []);
  }, [comment]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        assignUserBtnRef.current &&
        !assignUserBtnRef.current.contains(event.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setShowUserList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const isSaveButtonDisabled = () => {
    const currentTaggedUsers = new Set(taggedUsers);
    const initialTaggedUsers = new Set(initialAssignedUsers);

    const hasNoteChanged = editedNote !== comment?.note;
    const haveTaggedUsersChanged =
      currentTaggedUsers.size !== initialTaggedUsers.size ||
      [...currentTaggedUsers].some((user) => !initialTaggedUsers.has(user)) ||
      [...initialTaggedUsers].some((user) => !currentTaggedUsers.has(user));

    return !hasNoteChanged && !haveTaggedUsersChanged;
  };

  const handleSave = () => {
    onEdit(editedNote, Array.from(taggedUsers));
    setIsEditing(false);
    setShowUserList(false);
    onClose();
  };

  const handleEditCancel = () => {
    setEditedNote(comment?.note || '');
    setAssignedUsers(initialAssignedUsers);
    setTaggedUsers(new Set(initialAssignedUsers));
    setShowUserList(false);
    setIsEditing(false);
  };

  const handleUserAssignClick = () => {
    setShowUserList((prev) => !prev);
  };

  const handleUserClick = (user: any) => {
    if (assignedUsers.includes(user.key)) {
      setTaggedUsers((prev) => {
        const newSet = new Set(prev);
        newSet.delete(user.key);
        return newSet;
      });
      setAssignedUsers((prev) => prev.filter((id) => id !== user.key));
    } else {
      setTaggedUsers((prev) => {
        const newSet = new Set(prev);
        newSet.add(user.key);
        return newSet;
      });
      setAssignedUsers((prev) => [...prev, user.key]);
    }
  };

  const menuItems = allHr
    ? allHr
        .filter((user: any) => user && user.id)
        .map((user: any) => ({
          key: user.id,
          label: (
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-x-1'>
                <div
                  style={{
                    width: '30px',
                    height: '30px'
                  }}
                >
                  <img
                    src={user?.profilePic || '/user-pic.svg'}
                    alt=''
                    className='object-cover rounded-full'
                    referrerPolicy='no-referrer'
                  />
                </div>
                <span className='ml-2'>{user.name}</span>
              </div>
              {assignedUsers && assignedUsers.includes(user.id) && (
                <MdOutlineCheck className='inline-block text-light-blue' />
              )}
            </div>
          )
        }))
    : [];

  const assignedTo = allHr
    .filter((hr: any) => comment?.tagged_users.includes(hr.id))
    .map((hr: any) => hr.name);

  const formatAssignedTo = (users: string[]) => {
    if (users.length <= 2) return users.join(', ');
    const visibleUsers = users.slice(0, 2).join(', ');
    const remainingCount = users.length - 2;
    return `${visibleUsers}, ...+${remainingCount}`;
  };

  return (
    <Modal
      className='view-comment-modal'
      centered
      visible={open}
      onCancel={onClose}
      destroyOnClose
      width='1099px'
      footer={null}
    >
      <div className='flex justify-between'>
        <div className='px-4 md:px-5'>
          <h3 className='text-xl mb-0 uppercase font-semibold text-blue-radic'>
            Comment Details
          </h3>
        </div>
      </div>
      <div className='flex justify-between items-center mt-8 px-4 md:px-5'>
        <p className='text-base mb-0 text-blue-radic'>
          <strong>Author:</strong> {comment?.user?.name}
        </p>
        <p className='text-base mb-0 text-blue-radic'>
          <strong>Commented On:</strong>{' '}
          {moment(comment?.created_at).format(formatsDateTime)}
        </p>
      </div>
      {assignedTo && assignedTo.length > 0 && (
        <div className='flex justify-between items-center px-4 md:px-5'>
          <p className='text-base mb-0 text-blue-radic'>
            <strong>Assigned To:</strong>{' '}
            <Tooltip title={assignedTo.join(', ')}>
              {formatAssignedTo(assignedTo)}
            </Tooltip>
          </p>
        </div>
      )}
      <p className='px-4 md:px-5 mb-0 text-base text-blue-radic'>
        <strong>Comment: </strong>
      </p>
      <div className='px-4 md:px-5 mb-0 max-h-[305px] overflow-y-auto'>
        {isEditing ? (
          <Input.TextArea
            className='!text-base !rounded-lg'
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
            rows={8}
          />
        ) : (
          comment?.note?.split('\n').map((note, index) => (
            <p key={index} className='text-base pb-0 text-blue-radic'>
              {note}
            </p>
          ))
        )}
      </div>
      <div className='relative' ref={menuRef}>
        {showUserList && (
          <>
            <Menu
              className='rounded-lg !ml-[58px] !border-0 !shadow-lg !m-0'
              style={{
                position: 'absolute',
                bottom: '-8px',
                right: '165px',
                zIndex: 1000,
                width: 'auto',
                minWidth: '250px',
                maxHeight: '250px',
                overflowY: 'auto'
              }}
            >
              <div className='flex items-center justify-between px-4 py-4 sticky top-0 left-0 z-10 bg-white'>
                <span className='font-semibold'>Select Users</span>
                <span
                  className='w-5 h-5 flex items-center justify-center bg-gray-lighter rounded-full cursor-pointer'
                  onClick={() => setShowUserList(false)}
                >
                  <AiOutlineClose className='w-3 h-3 text-gray-light' />
                </span>
              </div>
              {menuItems.map((item: any) => (
                <Menu.Item
                  className={`${
                    assignedUsers.includes(item.key) ? 'bg-[#DEE9FC]' : ''
                  } hover:bg-[#DEE9FC]`}
                  onClick={handleUserClick}
                  key={item.key}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
            <TiArrowSortedDown className='absolute right-[275px] text-black text-[23px]' />
          </>
        )}
      </div>
      <div className='flex items-center justify-end px-4 mt-3 md:px-5 space-x-4'>
        {isEditing ? (
          <>
            <Tooltip placement='bottom' title='Assign Users'>
              <button
                ref={assignUserBtnRef}
                className='border-none cursor-pointer'
                onClick={handleUserAssignClick}
              >
                <FaRegUserCircle
                  color={`${assignedUsers.length > 0 ? '#2D94F3' : '#c7c7c7'}`}
                  fontSize='20px'
                />
              </button>
            </Tooltip>
            <button
              className={`h-10 w-28 rounded-md ${
                isSaveButtonDisabled()
                  ? 'cursor-not-allowed bg-[#f5f5f5] text-[#c7c7c7]'
                  : 'bg-light-blue hover:bg-[#40a9ff] text-white'
              }`}
              onClick={handleSave}
              disabled={isSaveButtonDisabled()}
            >
              Save
            </button>
            <button
              className='h-10 w-28 rounded-md border text-red-500 border-red-500'
              onClick={handleEditCancel}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            {isSameUser && (
              <button
                className='h-10 w-28 rounded-md bg-light-blue text-white hover:bg-[#40a9ff]'
                onClick={handleEdit}
              >
                Edit
              </button>
            )}

            <button
              className='h-10 w-28 rounded-md border text-red-500 border-red-500'
              onClick={onClose}
            >
              Close
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps)(ViewComment);
