import actions from './actions';

const initialState = {
  applications: {},
  allApplications: {},
  singleApplication: {},
  applicationNotes: [],
  duplicateApplication: [],
  search: '',
  currentPage: 1,
  interviewDetails: [],
  modalOpen: false,
  openInterviewModal: false,
  openInterviewHistoryModal: false,
  openDuplicateApplicationModal: false,
  interviewHistoryId: '',
  applicationRefId: '',
  interviewHistory: {},
  openCommentModal: false,
  openViewCommentModal: false,
  applicationLoading: true,
  taskModalOpen: false,
  taskList: [],
  updateStatusModal: false,
  currentStatus: '',
  firstId: '',
  nextApplication: {},
  prevApplication: {},
  currentId: '',
  concentLoading: true,
  allHr: [],
  postInterviewResponse: [],
  generateAQuestionSet: [],
  generateAQuestionSetGeneric: [],
  formErrors: {},
  interviewLoading: false,
  allTags: {},
  rejectedReason: null
};

export default function applicationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    case actions.GET_ALL_HRS_SUCCESS: {
      return { ...state, allHr: payload.applications };
    }
    case actions.ADD_COMMENT: {
      return {
        ...state,
        applicationNotes: [...state.applicationNotes, { ...payload }]
      };
    }
    case actions.ADD_SCHEDULE_INTERVIEW: {
      return {
        ...state,
        interviewDetails: [{ ...payload }, ...state.interviewDetails]
      };
    }

    case actions.TOGGLE_MODAL: {
      return {
        ...state,
        modalOpen: payload
      };
    }
    case actions.TOGGLE_TASK_MODAL: {
      return {
        ...state,
        taskModalOpen: payload
      };
    }
    case actions.TOGGLE_INTERVIEW_MODAL: {
      return {
        ...state,
        openInterviewModal: payload
      };
    }
    case actions.TOGGLE_INTERVIEW_HISTORY_MODAL: {
      return {
        ...state,
        openInterviewHistoryModal: payload.status,
        interviewHistoryId: payload.id
      };
    }
    case actions.GET_DUPLICATE_APPLICATION_MODAL: {
      return {
        ...state,
        openDuplicateApplicationModal: payload.status,
        applicationRefId: payload.ref_id
      };
    }
    case actions.TOGGLE_COMMENT_MODAL: {
      return {
        ...state,
        openCommentModal: payload
      };
    }
    case actions.TOGGLE_VIEW_COMMENT_MODAL: {
      return {
        ...state,
        openViewCommentModal: payload
      };
    }
    case actions.TOGGLE_UPDATE_STATUS_MODAL: {
      return {
        ...state,
        updateStatusModal: payload
      };
    }
    case actions.UPDATE_CURRENT_STATUS: {
      return {
        ...state,
        currentStatus: payload
      };
    }
    case actions.SET_REJECTED_REASON: {
      return {
        ...state,
        rejectedReason: payload
      };
    }
    // case actions.POST_INTERVIEW_RESPONSE: {
    //   return {
    //     ...state,
    //     postInterviewResponse: payload,
    //   };
    // }
    case actions.GENERATE_A_QUESTION_SET_SUCCESS: {
      return {
        ...state,
        generateAQuestionSet: payload
      };
    }
    case actions.GET_INTERVIEW_HISTORY_SUCCESS: {
      return {
        ...state,
        interviewHistory: payload.interviewHistory
      };
    }

    case actions.SET_ALL_TAGS: {
      return {
        ...state,
        allTags: payload
      };
    }

    case actions.RESET_STATE: {
      return {
        ...state,
        taskList: [],
        applicationNotes: [],
        interviewDetails: [],
        singleApplication: {},
        interviewLoading: false
      };
    }
    case actions.RESET_INTERVIEW_HISTORY_STATE: {
      return {
        ...state,
        interviewHistory: {}
      };
    }
    case actions.SET_INTERVIEW_LOADING: {
      return {
        ...state,
        interviewLoading: !state.interviewLoading
      };
    }

    default: {
      return state;
    }
  }
}

export function generateAQuestionSet(state = initialState, { type, payload }) {
  switch (type) {
    case actions.GENERATE_A_QUESTION_SET_SUCCESS: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}

export function generateAQuestionSetGeneric(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case actions.GENERATE_A_QUESTION_SET_GENERIC: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}
