import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
  Value
} from 'react-phone-number-input';

interface IPhoneInputProps {
  className: string;
  value?: string;
  onChange: (value: Value) => void;
  placeholder: string;
  name?: string;
}

const PhoneNumberInput: React.FC<IPhoneInputProps> = ({
  className,
  value,
  onChange,
  placeholder,
  name
}) => {
  return (
    <>
      <PhoneInputWithCountrySelect
        defaultCountry='IN'
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name ?? ''}
      />
      <div className='error-message absolute bottom-[-20px]'>
        {value && value !== ''
          ? isValidPhoneNumber(value)
            ? ''
            : 'Please enter a valid phone number.'
          : ''}
      </div>
    </>
  );
};

export default PhoneNumberInput;
