const actions = {
  SET_STATE: `SET_STATE`,
  GET_ALL_USERS: `GET_ALL_USERS`,
  POST_INVITE_MAIL: `POST_INVITE_MAIL`,
  UPDATE_ROLE: `UPDATE_ROLE`,
  EDIT_USER_PROFILE: `EDIT_USER_PROFILE`,
  UPDATE_USER_STATUS: `UPDATE_USER_STATUS`
};

export default actions;
