import { Input, Modal, Spin, Tooltip } from 'antd';
import { isString } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlinePlusCircle } from 'react-icons/ai';
import PrimaryButton from '../../Buttons/Primary';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import referralActions from '../../../redux/referrals/actions';
import FileUpload from '../../FileUpload/ApplicantFileUpload';
import PortfolioFileUpload from '../../FileUpload/PortfolioFileUpoad';
import TextArea from 'antd/lib/input/TextArea';
import { MdCancel } from 'react-icons/md';
import AutoComplete from '../../AutoComplete/AutoComplete';
import JobsServices from '../../../services/jobs';
import JobOptionBox from '../../JobOptionBox/JobOptionBox';
import PhoneNumberInput from '../../PhoneNumberInput';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  jobsForAddApplicant: state.jobs.jobsForAddApplicant,
  singleReferral: state.referrals.singleReferral
});
interface IJobSelect {
  jobsForAddApplicant: any;
  heading: string;
  page: any;
  id: string;
  singleReferral: any;
  status: any;
  loading: boolean;
}

const AddToATS: React.FC<IJobSelect> = ({
  jobsForAddApplicant,
  page,
  heading,
  id,
  singleReferral,
  status,
  loading
}) => {
  // const [jobId, setJobId] = useState<{ id?: number; portFolioShow?: boolean }>(
  //   {}
  // );
  const [portFolioShow, setPortFolioShow] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<any>();
  const [jobRefId, setJobRefId] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [gradYear, setGradYear] = useState<number | null>(null);
  const [lakhs, setLakhs] = useState('');
  const [thousand, setThousand] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [valid, setValid] = useState(false);
  const [fileName, setFileName] = useState<any>();
  const [fileType, setFileType] = useState<any>();
  const [attachmentVisible, setAttachmentVisible] = useState(true);
  const [attachmentURL, setAttachmentURL] = useState<any>('');
  const [portfolioFileName, setPortfolioFileName] = useState('');
  const [portfolioFileType, setPortfolioFileType] = useState('');
  const [portfolioLinks, setPortfolioLinks] = useState('');
  const [errors, setErrors] = useState({
    // mobileNumber: '',
    email: '',
    gradYear: ''
  });
  const [files, setFiles] = useState<any>({});
  const jobService = new JobsServices();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setGradYear(currentYear);
    setLakhs('00');
    setThousand('00');
  }, []);

  useEffect(() => {
    // if (singleReferral?.data?.job_id) {
    //   let [title, id, portfolioBoolean] = getJobTitle(
    //     singleReferral?.data?.job_id,
    //     true
    //   );
    //   setJobId({
    //     id: singleReferral?.data?.job_id,
    //     portFolioShow: portfolioBoolean
    //   });
    // }
    // setJobId(singleReferral?.data?.job_id);
    if (singleReferral?.data?.job_id) {
      setPortFolioShow(singleReferral?.data?.portfolio_required);
    }
    setJobTitle(singleReferral?.data?.job_title);
    setFullName(singleReferral?.data?.full_name);
    setEmail(singleReferral?.data?.email);
    setMobileNumber('+' + singleReferral?.data?.contact?.toString());
    setJobRefId(singleReferral?.data?.job_id || '');
    setAttachmentURL(singleReferral?.data?.resume_url);
    setFileName(singleReferral?.data?.resume);
    setFileType(singleReferral?.data?.resume_mime_type);
  }, [singleReferral?.data]);

  useEffect(() => {
    if (
      jobTitle?.trim().length > 0 &&
      fullName.trim().length > 0 &&
      email.trim().length > 0 &&
      mobileNumber?.toString().length! > 0 &&
      gradYear?.toString().length! > 0 &&
      lakhs.length > 0 &&
      lakhs.length < 3 &&
      thousand.length > 0 &&
      thousand.length < 3 &&
      (fileName?.trim().length > 0 || attachmentURL !== null)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [
    jobTitle,
    fullName,
    email,
    mobileNumber,
    gradYear,
    lakhs,
    thousand,
    fileName,
    attachmentURL,
    portfolioFileName
  ]);

  const onSave = () => {
    const errorsObject = {
      // mobileNumber:
      //   mobileNumber?.toString().length !== 10 ? 'Invalid Mobile number' : '',
      email: validateEmail(email) ? '' : 'Invalid email',
      gradYear:
        gradYear! < new Date().getFullYear() + 7
          ? ''
          : 'Invalid graduation year'
    };
    setErrors(errorsObject);
    if (
      // !errorsObject.mobileNumber &&
      !errorsObject.email &&
      !errorsObject.gradYear
    ) {
      let ctc;
      if (lakhs?.toString().length === 1 && thousand?.toString().length === 1) {
        ctc = Number(`0${lakhs}0${thousand}000`);
      } else if (
        lakhs?.toString().length === 1 &&
        thousand?.toString().length === 2
      ) {
        ctc = Number(`0${lakhs}${thousand}000`);
      } else if (
        lakhs?.toString().length === 2 &&
        thousand?.toString().length === 1
      ) {
        ctc = Number(`${lakhs}0${thousand}000`);
      } else {
        ctc = Number(`${lakhs}${thousand}000`);
      }
      const body = {
        job_id: jobRefId,
        ctc_in_rupees: Number(ctc),
        full_name: fullName,
        graduation_year: Number(gradYear),
        contact: Number(mobileNumber),
        email: email,
        resume: fileName,
        resume_mime_type: fileType,
        referral_id: singleReferral?.data?.uuid,
        portfolio_file: portfolioFileName ? portfolioFileName : '',
        portfolio_file_mime_type: portfolioFileType ? portfolioFileType : '',
        portfolio_links: portfolioLinks ? portfolioLinks : '',
        applied_from: 7,
        specific_applied_from: ''
      };
      const formData = getFormData(body);
      dispatch({
        type: applicationActions.ADD_APPLICANT,
        payload: {
          body: formData,
          flag: 'get_hr_referal',
          page: page ?? 1
        }
      });
      setJobTitle(null);
      setEmail('');
      setFullName('');
      setMobileNumber('');
      setIsOpen(false);
      setPortfolioLinks('');
    }
  };

  const showModal = () => {
    if (status === 0) {
      dispatch({
        type: referralActions.GET_SINGLE_REFERRAL,
        payload: {
          id
        }
      });
      setIsOpen(true);
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    handleChange(null);
    setAttachmentURL(singleReferral?.data?.resume_url);
    setAttachmentVisible(true);
    setPortfolioFileName('');
    setPortfolioLinks('');
    setErrors({
      // mobileNumber: '',
      email: '',
      gradYear: ''
    });
  };
  const dispatch = useDispatch();
  const handleAttachmentCancel = () => {
    setAttachmentVisible(false);
    setAttachmentURL(null);
    setFileName(null);
    setFileType(null);
  };
  const handleChange = (data: any) => {
    if (!data) {
      setJobRefId('');
      setPortFolioShow(false);
      setJobTitle('');
    } else {
      setJobRefId(String(data?.job_ref_id));
      setJobTitle(data?.job_title);
      setPortFolioShow(data?.portfolio_required);
    }
    setPortfolioFileName('');
    setPortfolioFileType('');
    setPortfolioLinks('');
    setFiles('');
  };

  const getFormData = (object: any) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
  const getJobTitle = (jobId: any, custom = false) => {
    if (custom) {
      let jobTitle = jobsForAddApplicant?.data?.filter(
        (job: any) => jobId === job?.job_ref_id
      );
      let title = jobTitle[0]?.job_title;
      let id = jobTitle[0]?.job_ref_id;
      let portfolioBoolean = jobTitle[0]?.portfolio_required;
      return [title, id, portfolioBoolean];
    }
    let jobTitle = jobsForAddApplicant?.data?.filter(
      (job: any) => jobId === job?.id
    );
    let title = jobTitle[0]?.job_title;
    let id = jobTitle[0]?.job_ref_id;
    let portfolioBoolean = jobTitle[0]?.portfolio_required;
    return [title, id, portfolioBoolean];
  };
  function validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const customJSX = (item: any): ReactNode => {
    return JobOptionBox({ data: item });
  };

  return (
    <>
      <Tooltip placement='topRight' title='Add to ATS'>
        <div
          className={`w-8 h-8 bg-green-100 rounded-md flex justify-center items-center space-x-2 ${
            status === 0
              ? 'text-green-500 cursor-pointer'
              : 'cursor-not-allowed text-gray-light'
          } `}
          onClick={showModal}
        >
          <AiOutlinePlusCircle className='w-4 h-5' />
          {/* <p className='pb-0 mb-0'>Add to ATS</p> */}
        </div>
      </Tooltip>
      {!loading && (
        <Modal
          className='edit-task-modal'
          visible={isOpen}
          onCancel={handleCancel}
          destroyOnClose={true}
          width='1099px'
          footer={null}
        >
          <div className='bg-[#FAFDFF]'>
            <div className='flex justify-between '>
              <div className=''>
                <h3 className='text-xl text-center font-semibold text-blue-zodiac'>
                  {heading}
                </h3>
              </div>
              <div
                className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                onClick={handleCancel}
              >
                <AiOutlineClose className='w-5 h-5 text-gray-light' />
              </div>
            </div>

            <div className='flex flex-col space-y-[30px]'>
              <div className='grid grid-cols-2'>
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    Job Title
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>

                  <AutoComplete
                    apiService={jobService.GetAllJobsForFilter}
                    key='job_id'
                    placeholder='All Jobs'
                    isPayload
                    setselectedValue={(data: any) => {
                      handleChange(data);
                    }}
                    edit
                    customJSX={customJSX}
                    defaultValue={
                      String(jobRefId)?.length &&
                      isString(String(jobRefId)) &&
                      jobTitle?.length
                        ? `${jobRefId}~${jobTitle}`
                        : ''
                    }
                    // reset={reset}
                    className='edit-input text-base capitalize'
                  />
                </div>
                <div className='ml-2'>
                  <p className='applicant-modal-label'>
                    Full Name
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base capitalize'
                    value={fullName}
                    onChange={(e: any) => setFullName(e.target.value)}
                    placeholder='Enter full name'
                  />
                </div>
              </div>
              <div className='flex justify-between space-x-3'>
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    Email
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base'
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    placeholder='Enter email'
                  />
                  {errors.email !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    Mobile Number
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <PhoneNumberInput
                    className='edit-input text-base capitalize'
                    value={mobileNumber ?? ''}
                    onChange={(value: any) => setMobileNumber(value)}
                    placeholder='Enter Mobile Number'
                  />
                  {/* {errors.mobileNumber !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.mobileNumber}
                    </div>
                  )} */}
                </div>
              </div>
              <div className='grid grid-cols-2 gap-3'>
                <div className=''>
                  <p className='applicant-modal-label'>
                    Graduation Year
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base capitalize'
                    value={gradYear ?? ''}
                    onChange={(e: any) => setGradYear(e.target.value)}
                    placeholder='Enter graduation year'
                    type='number'
                    max={9999}
                  />
                  {errors.gradYear !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.gradYear}
                    </div>
                  )}
                </div>
                <div>
                  <div>
                    <p className='applicant-modal-label'>
                      Current Annual CTC(INR)
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <div className='flex space-x-3'>
                      <Input
                        className='edit-input text-base capitalize'
                        value={lakhs}
                        onChange={(e: any) => setLakhs(e.target.value)}
                        type='number'
                        placeholder='Enter in lakhs'
                      />
                      <Input
                        className='edit-input text-base capitalize'
                        value={thousand}
                        onChange={(e: any) => setThousand(e.target.value)}
                        type='number'
                        placeholder='Enter in thousands'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative'>
                <p className='applicant-modal-label'>
                  Resume/CV Upload (.pdf, .docx)
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <div
                  className={`${
                    attachmentURL && attachmentURL !== '' && attachmentVisible
                      ? 'task-input'
                      : ''
                  } relative`}
                >
                  <div className='cursor-pointer'>
                    <FileUpload
                      setFileName={setFileName}
                      fileType={fileType}
                      setFileType={setFileType}
                      setAttachmentVisible={setAttachmentVisible}
                    />
                  </div>
                  {attachmentURL &&
                    attachmentURL !== '' &&
                    attachmentVisible && (
                      <div className='flex absolute top-1 space-x-1 left-48'>
                        <a
                          className=''
                          target='_blank'
                          rel='noopener noreferrer'
                          href={attachmentURL}
                        >
                          Link{' '}
                        </a>
                        <div className='h-full mt-[2px] cursor-pointer'>
                          <MdCancel
                            size={18}
                            onClick={handleAttachmentCancel}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {portFolioShow && jobRefId ? (
                <div className='grid grid-cols-2 gap-3'>
                  <div>
                    <p className='applicant-modal-label'>
                      Portfolio Upload (.pdf/.docx)
                    </p>
                    <div className='cursor-pointer'>
                      <PortfolioFileUpload
                        setPortfolioFileName={setPortfolioFileName}
                        portfolioFileType={portfolioFileType}
                        setPortfolioFileType={setPortfolioFileType}
                        files={files}
                        setFiles={setFiles}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className='applicant-modal-label'>Portfolio Links</p>
                      <TextArea
                        className='edit-input text-base flex ml-0'
                        placeholder='Your best Dribble posts? Behance profile? Enter the links here...'
                        rows={2}
                        name='portfolio-links'
                        value={portfolioLinks}
                        onChange={(e) => {
                          setPortfolioLinks(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
                <PrimaryButton disabled={!valid} text='Save' onClick={onSave} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default connect(mapStateToProps)(AddToATS);
