import React, { FC, useEffect, useRef, useState } from 'react';
import Comment from '../../Comment';
import EmptyComment from '../../EmptyComment';
import applicationActions from '../../../redux/applications/actions';
import { connect, useDispatch } from 'react-redux';
import DeleteComment from '../../Modal/Delete/deleteComment';
import ViewComment from '../../Modal/ViewDetails/ViewCommentDetails';

const mapStateToProps = (state: any) => ({
  userId: state.auth.userId,
  singleApplication: state.applications.singleApplication,
  applicationNotes: state.applications.applicationNotes
});

interface ICommentsComp {
  userId: any;
  singleApplication: any;
  applicationNotes: any;
  commentId: any;
  setCommentId: any;
  id: any;
}

const CommentsComp: FC<ICommentsComp> = ({
  userId,
  singleApplication,
  applicationNotes,
  commentId,
  setCommentId,
  id
}) => {
  const [comments, setComments] = useState<any>([]);
  const [isEdit, setEdit] = useState(false);
  const [selectedComment, setSelectedComment] = useState<any>(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const commentRef = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    setComments([...applicationNotes]);
    const el = document.getElementById('comment');
    commentRef.current.scrollTo({
      top: el?.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }, [
    applicationNotes?.length,
    applicationNotes,
    commentRef,
    comments?.length
  ]);

  useEffect(() => {
    if (singleApplication?.id) {
      dispatch({
        type: applicationActions.GET_APPLICATION_NOTE,
        payload: { ref_id: singleApplication?.id }
      });
    }
  }, [singleApplication?.id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: applicationActions.SET_STATE,
        payload: { applicationNotes: [] }
      });
    };
  }, []);

  const getCommentId = (value: string) => {
    setCommentId(value);
  };

  const handleCommentAdd = (value: string, taggedUserIds: string[]) => {
    if (isEdit) {
      setEdit(false);
      dispatch({
        type: applicationActions.UPDATE_COMMENT,
        payload: {
          ref_id: singleApplication?.id,
          commentId,
          body: {
            note: value,
            tagged_users: taggedUserIds
          }
        }
      });
    } else {
      setEdit(false);
      dispatch({
        type: applicationActions.CREATE_APPLICATION_NOTE,
        payload: {
          ref_id: singleApplication?.id,
          body: {
            note: value,
            tagged_users: taggedUserIds
          }
        }
      });
    }
  };

  const handleViewComment = (comment: any) => {
    setSelectedComment(comment);
    setIsViewModalOpen(true);
    dispatch({
      type: applicationActions.TOGGLE_VIEW_COMMENT_MODAL,
      payload: true
    });
  };

  const handleEditComment = (newNote: string, taggedUsersIDs: string[]) => {
    // Update the comment in the local state
    setComments((prevComments: any[]) =>
      prevComments.map((comment) =>
        comment.id === selectedComment.id
          ? { ...comment, note: newNote }
          : comment
      )
    );
    dispatch({
      type: applicationActions.UPDATE_COMMENT,
      payload: {
        ref_id: singleApplication?.id,
        commentId: selectedComment.id,
        body: {
          note: newNote,
          tagged_users: taggedUsersIDs
        }
      }
    });
    setIsViewModalOpen(false);
  };

  return (
    <div className='flex flex-col p-5 bg-white rounded-lg w-full '>
      <div className='flex items-center mb-4'>
        <h2 className='mr-3 text-[20px]'>Comments</h2>
        {comments.length > 0 && (
          <span className='mb-2 bg-[#2D94F33E] text-light-blue opacity-50 p-1 rounded'>
            {comments.length < 10 ? `0 ${comments.length}` : comments.length}
          </span>
        )}
      </div>
      <div
        id='comment'
        ref={commentRef}
        className='overflow-y-scroll max-h-[200px]'
      >
        {comments.length > 0 ? (
          comments.map((data: any) => (
            <Comment
              key={data.id}
              data={data}
              getCommentId={getCommentId}
              userId={userId}
              setEdit={setEdit}
              isEdit={isEdit}
              onView={handleViewComment}
            />
          ))
        ) : (
          <div className='w-full flex items-center flex-col justify-center h-full py-12'>
            <img src='/Icons/NoComment.svg' alt='No Comments' />
            <span className='text-lg text-[#0E254B] opacity-40 font-semibold'>
              No Comments.
            </span>
          </div>
        )}
      </div>
      <div className='last:mt-auto'>
        <EmptyComment
          onPost={(text: string, taggedUserIds: string[]) => {
            handleCommentAdd(text, taggedUserIds);
          }}
          isEdit={isEdit}
          commentId={commentId}
          setEdit={setEdit}
        />
      </div>
      <DeleteComment
        desc='Are You Sure You Want To Delete This Comment?'
        heading='DELETE COMMENT'
        userId={id}
        commentId={commentId}
      />
      {selectedComment && (
        <ViewComment
          isSameUser={userId === selectedComment?.user?.id}
          onClose={() => {
            dispatch({
              type: applicationActions.TOGGLE_VIEW_COMMENT_MODAL,
              payload: false
            });
            setIsViewModalOpen(false);
          }}
          comment={selectedComment}
          onEdit={handleEditComment}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(CommentsComp);
