import { all, takeEvery, put } from 'redux-saga/effects';
import { doApiCall } from '../../helper';
import actions from './actions';
import authActions from '../auth/actions';
import UserServices from '../../services/users';
import { notification } from 'antd';

const services = new UserServices();

export function* WATCH_GET_ALL_USERS(action) {
  const response = yield doApiCall(services.GetAllUsers, action.payload);
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { users: response } });
  }
}

export function* WATCH_POST_INVITE_MAIL(action) {
  const response = yield doApiCall(services.PostInviteMail, action.payload);
  if (response?.success) {
    notification.success({ message: 'User Invited' });
    yield put({
      type: actions.GET_ALL_USERS,
      payload: { page: 1 }
    });
  }
}

export function* WATCH_UPDATE_ROLE(action) {
  const response = yield doApiCall(
    services.UpdateRoleAndStatus,
    action.payload
  );
  if (response?.success) {
    notification.success({ message: 'User Role updated' });
    yield put({
      type: actions.GET_ALL_USERS,
      payload: { page: 1 }
    });
  }
}

export function* WATCH_UPDATE_USER_STATUS(action) {
  const response = yield doApiCall(
    services.UpdateRoleAndStatus,
    action.payload
  );
  if (response?.success) {
    notification.success({ message: 'User Status updated' });
    yield put({
      type: actions.GET_ALL_USERS,
      payload: { page: 1 }
    });
  }
}

export function* WATCH_UPDATE_USER_PROFILE(action) {
  const response = yield doApiCall(services.UpdateUserProfile, action.payload);
  if (response?.success) {
    const { data } = response;
    notification.success({ message: 'User Profile updated' });
    const { id, name, email, profilePic, contact, role, status, job_title } =
      data;
    yield put({
      type: authActions.SET_STATE,
      payload: { contact }
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL_USERS, WATCH_GET_ALL_USERS)]);
  yield all([takeEvery(actions.POST_INVITE_MAIL, WATCH_POST_INVITE_MAIL)]);
  yield all([takeEvery(actions.UPDATE_ROLE, WATCH_UPDATE_ROLE)]);
  yield all([takeEvery(actions.UPDATE_USER_STATUS, WATCH_UPDATE_USER_STATUS)]);
  yield all([takeEvery(actions.EDIT_USER_PROFILE, WATCH_UPDATE_USER_PROFILE)]);
}
