import { Pagination, Select, Spin, Tooltip } from 'antd';
import React, { FC, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import AddModal from '../../Modal/AddJob';
import EditModal from '../../Modal/Edit/EditUserRole';
import userActions from '../../../redux/users/actions';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../../helper';
import { history } from '../../../utilities/configureAxios';
import { USER_ROLE } from '../../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  users: state.users.users,
  loading: state.auth.loading
});

interface InputProps {
  inputHeading: string;
  placeholder: string;
}

interface InputDetails {
  inputDetails: InputProps[];
  users: any;
  loading: boolean;
  location: any;
}
interface Statusprops {
  status: any;
  userId: any;
  role: any;
}

const HrTable: FC<InputDetails> = ({
  inputDetails,
  users,
  loading,
  location
}) => {
  const { page } = getCurrentQueryParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: userActions.GET_ALL_USERS, payload: { page: page ?? 1 } });
  }, [page, dispatch]);

  const StatusSelect: React.FC<Statusprops> = ({ status, userId, role }) => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const handleChange = (value: any) => {
      if (value === 'Active') {
        dispatch({
          type: userActions.UPDATE_USER_STATUS,
          payload: { id: userId, body: { status: 1, role } }
        });
      } else if (value === 'Inactive') {
        dispatch({
          type: userActions.UPDATE_USER_STATUS,
          payload: { id: userId, body: { status: 0, role } }
        });
      }
    };

    return (
      <>
        <Select
          className={`select-box w-[70%] ${
            status === 'Active'
              ? 'table-select-box-success'
              : 'table-select-box-error'
          }`}
          defaultValue={status}
          onChange={handleChange}
        >
          <Option value='Active' className='select-box-option'>
            <span className='text-[#52c41a]'>Active</span>
          </Option>
          <Option value='Inactive' className='select-box-option'>
            <span className='text-[#ff4d4f]'>Inactive</span>
          </Option>
        </Select>
      </>
    );
  };

  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <div className='flex flex-col'>
          <div className='flex justify-end my-5 mx-8'>
            <AddModal heading='ADD HR/HR Lead' inputDetails={inputDetails} />
          </div>
          <div className='bg-white shadow-lg mx-8 rounded-2xl overflow-auto min-h-[80%]'>
            <table className='w-full table_transition'>
              <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                <tr className='text-[0.9rem] px-2'>
                  <th className='text-[#233A5C] text-opacity-50 text-center py-4 w-[10%] max-w-[10%]'>
                    S.No
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4 w-[25%] max-w-[25%]'>
                    Name
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4 w-[30%] max-w-[30%]'>
                    Email
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4 w-[10%] max-w-[10%]'>
                    Role
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4 w-[20%] max-w-[20%]'>
                    Status
                  </th>
                  <th className='text-[#233A5C] text-opacity-50 py-4 w-[10%] max-w-[10%]'>
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {users &&
                  users?.data?.length > 0 &&
                  users?.data?.map((d: any, i: any) => (
                    <tr
                      key={i}
                      className='text-base border-bottom text-blue-zodiac table-text text-left font-normal capitalize mb-0 whitespace-nowrap overflow-auto'
                    >
                      <td className='py-2 text-table-header text-center'>
                        {users?.meta?.pagination?.perPage *
                          (users?.meta?.pagination?.page - 1) +
                          i +
                          1}
                      </td>
                      <td className='py-2 text-table-header'>
                        <div className='font-medium flex items-center space-x-4'>
                          {d.profilePic ? (
                            <img
                              src={d.profilePic}
                              alt=''
                              width='28px'
                              height='28px'
                              className='rounded-full object-contain'
                            />
                          ) : (
                            <div className='user-pic'></div>
                          )}

                          <div className='flex items-center'>
                            <Tooltip placement='bottomLeft' title={d?.name}>
                              <p className='text-blue-zodiac font-normal table-text mb-0 pb-0 text-overflow-none max-w-[10rem]'>
                                {d.name}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                      <td className='py-2 text-table-header lowercase text-overflow-none max-w-[7rem]'>
                        <Tooltip placement='bottomLeft' title={d?.email}>
                          {d.email}
                        </Tooltip>
                      </td>
                      <td className='py-2 text-table-header text-overflow-none max-w-[7rem]'>
                        {d.role === USER_ROLE.HR ? 'HR' : 'HR Lead'}
                      </td>
                      <td className='py-2 text-table-header '>
                        {
                          <StatusSelect
                            status={d.status === 1 ? 'Active' : 'Inactive'}
                            userId={d.uuid}
                            role={d.role}
                          />
                        }
                      </td>
                      <td className='py-2 text-table-header '>
                        <EditModal
                          heading='Edit HR Details'
                          status={d.status}
                          userId={d.uuid}
                          role={d.role}
                          mobile={d.contact}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {users && users?.data?.length === 0 && (
              <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                Oops! No HRs to show
              </div>
            )}
          </div>
          <div className='px-4 lg:px-12 xl:px-16 flex flex-col  justify-center mt-5 mb-10'>
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                current={users?.meta?.pagination?.page}
                defaultPageSize={users?.meta?.pagination?.perPage}
                onChange={(page) =>
                  history.push(getSearchParamsUrl({ page: page }), {})
                }
                total={users?.meta?.pagination?.count}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default connect(mapStateToProps)(HrTable);
