import { Checkbox, Input, Rate, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/Primary';
import Header from '../../components/Header';
import {
  applicantFeedbackOptions,
  formattedBadConduct,
  formattedCommunication,
  formattedGoodConduct,
  formattedInternalOption
} from '../../helper';
import categoryActions from '../../redux/questionBank/actions';
import { imageData } from '../../Interfaces/common';
import DocumentsComponent from '../../components/FileUpload/DocumentUploader';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  getInterviewFeedback: state.getInterviewFeedback.getInterviewFeedback,
  getInterviewOverallFeedback:
    state.getInterviewOverallFeedback.getInterviewOverallFeedback,
  questionsList: state.getInterviewQuestionSet.questionsList,
  getSelectedInterview: state.interviewers.selectedInterview,
  singleInterviewInfo: state.getSingleInterviewInfo.singleInterviewInfo
});

function EditFeedback({
  getInterviewFeedback,
  getInterviewOverallFeedback,
  questionsList,
  loading,
  singleInterviewInfo
}: any) {
  const [formattedDataForQuestionSet, setFormattedDataForQuestionSet] =
    useState<any>([]);
  const [formatedAdditionalQuestionSet, setFormatedAdditionalQuestionSet] =
    useState<any>([]);
  const [overallTextInput, setOverallTextInput] = useState(
    getInterviewOverallFeedback?.data?.overall_feedback
  );
  const [overallRate, setOverallRate] = useState(
    getInterviewOverallFeedback?.data?.overall_rating
  );
  const [overallCommunication, setOverallCommunication] = useState<any>(
    formattedCommunication.filter(
      (i) =>
        Number(i.value) ==
        getInterviewOverallFeedback?.data?.communication_skill
    )
  );
  const [overallBadConduct, setOverallBadConduct] = useState<any>(
    getInterviewOverallFeedback?.data?.bad_conduct?.map((i: any) =>
      i.toString()
    )
  );
  const [overallGoodConduct, setOverallGoodConduct] = useState(
    getInterviewOverallFeedback?.data?.good_conduct?.map((i: any) =>
      i.toString()
    )
  );
  const [overallInternalCommunication, setOverallInternalCommunication] =
    useState<any>(
      formattedInternalOption?.filter(
        (i) =>
          Number(i.value) == getInterviewOverallFeedback?.data?.internal_option
      )
    );
  const [overallLevel, setOverallLevel] = useState(
    getInterviewOverallFeedback?.data?.level
  );
  const [overallThankYouMail, setOverallThankYouMail] = useState(
    getInterviewOverallFeedback?.data?.send_thankyou_mail ? true : false
  );
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState<boolean>();

  const [feedbackFile, setFeedbackFile] = useState<Array<any>>(
    getInterviewOverallFeedback?.data?.attachment || []
  );
  const [files, setFiles] = useState([]);

  const modulesQuestion = {
    toolbar: false
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams<{ id: any }>();

  useEffect(() => {
    const interviewId = location?.pathname?.split('/')[3];
    let body = {
      interviewId: interviewId
    };
    dispatch({
      type: categoryActions.GET_INTERVIEW_OVERALL_FEEDBACK,
      payload: body
    });

    return () => {
      dispatch({
        type: categoryActions.RESET_INTERVIEW_FEEDBACK_QUESTION_SET
      });
      dispatch({
        type: categoryActions.RESET_INTERVIEW_OVERALL_FEEDBACK
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const uuid = location?.pathname?.split('/')[3];
    let body = {
      uuid: uuid
    };
    dispatch({
      type: categoryActions.GET_SINGLE_INTERVIEW_INFO,
      payload: body
    });

    return () => {
      dispatch({
        type: categoryActions.RESET_INTERVIEW_QUESTION_SET
      });
      dispatch({
        type: categoryActions.GET_SINGLE_INTERVIEW_INFO_RESET
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const interviewId = location?.pathname?.split('/')[3];
    let body = {
      interviewId: interviewId
    };
    if (interviewId && singleInterviewInfo?.data?.question_set_status === 3) {
      dispatch({
        type: categoryActions.GET_INTERVIEW_FEEDBACK,
        payload: body
      });
    }
  }, [singleInterviewInfo]);

  useEffect(() => {
    const formatedQuestion = getInterviewFeedback?.data?.map((i: any) => {
      return {
        qf_id: i.uuid,
        content: i.content ? i.content : '',
        rating: i.rating ? i.rating : 0
      };
    });
    setFormattedDataForQuestionSet(formatedQuestion);
    const formatedAdditionalQuestion = getInterviewOverallFeedback?.data?.meta;
    setFormatedAdditionalQuestionSet(formatedAdditionalQuestion);
  }, [getInterviewFeedback, getInterviewOverallFeedback]);

  const handleEditFeedback = (e: any, index: number) => {
    const formattedList = formattedDataForQuestionSet.map((d: any, i: number) =>
      index === i ? { ...d, content: e.target.value } : { ...d }
    );
    const formatForPost = formattedList?.map((i: any) => {
      return {
        qf_id: i.qf_id,
        content: i.content ? i.content : '',
        rating: i.rating ? i.rating : 0
      };
    });
    setFormattedDataForQuestionSet(formatForPost);
    setIsBtnActive(false);
  };

  const handleEditRate = (e: any, index: number) => {
    const formattedList = formattedDataForQuestionSet.map((d: any, i: number) =>
      index === i ? { ...d, rating: e } : { ...d }
    );
    const formatForPost = formattedList?.map((i: any) => {
      return {
        qf_id: i.qf_id,
        content: i.content,
        rating: i.rating
      };
    });

    setFormattedDataForQuestionSet(formatForPost);
    setIsBtnActive(false);
  };
  const handleEditAdditionalQuestionFeedback = (e: any, index: number) => {
    const formattedList = formatedAdditionalQuestionSet.map(
      (d: any, i: number) =>
        index === i ? { ...d, content: e.target.value } : { ...d }
    );
    setFormatedAdditionalQuestionSet(formattedList);
    setIsBtnActive(false);
  };
  const handleEditAdditionalQuestionRatting = (e: any, index: number) => {
    const formattedList = formatedAdditionalQuestionSet.map(
      (d: any, i: number) => (index === i ? { ...d, rating: e } : { ...d })
    );
    setFormatedAdditionalQuestionSet(formattedList);
    setIsBtnActive(false);
  };

  useEffect(() => {
    setOverallTextInput(getInterviewOverallFeedback?.data?.overall_feedback);
    setOverallRate(getInterviewOverallFeedback?.data?.overall_rating);
    setOverallCommunication(
      formattedCommunication.filter(
        (i) =>
          Number(i?.value) ==
          getInterviewOverallFeedback?.data?.communication_skill
      )?.[0]?.value
    );
    setOverallBadConduct(() =>
      getInterviewOverallFeedback?.data?.bad_conduct?.map((i: any) =>
        i.toString()
      )
    );
    setOverallGoodConduct(() =>
      getInterviewOverallFeedback?.data?.good_conduct?.map((i: any) =>
        i.toString()
      )
    );
    setOverallInternalCommunication(
      formattedInternalOption.filter(
        (i) =>
          Number(i?.value) == getInterviewOverallFeedback?.data?.internal_option
      )?.[0]?.value
    );
    setOverallLevel(getInterviewOverallFeedback?.data?.level);
    setOverallThankYouMail(
      getInterviewOverallFeedback?.data?.send_thankyou_mail
    );
    setApplicationStatus(getInterviewOverallFeedback?.data?.in_next_round);
    if (
      getInterviewOverallFeedback?.data?.uuid &&
      getInterviewOverallFeedback?.data?.attachment?.length
    ) {
      setFeedbackFile(
        getInterviewOverallFeedback?.data?.attachment?.map((val: imageData) => {
          return {
            uuid: val?.slug,
            name: val?.name,
            type: val?.type,
            slug: val?.slug,
            url: val?.attachment_url,
            status: 'done'
          };
        })
      );
      setFiles(
        getInterviewOverallFeedback?.data?.attachment?.map((val: imageData) => {
          return {
            uuid: val?.slug,
            name: val?.name,
            type: val?.type,
            slug: val?.slug,
            url: val?.attachment_url,
            status: 'done'
          };
        })
      );
    }
    // setFeedbackFileURL(getInterviewOverallFeedback?.data?.attachment);
    // setFeedbackFileMimeType(
    //   getInterviewOverallFeedback?.data?.attachment_mime_type
    // );
  }, [getInterviewOverallFeedback]);
  const handleEditOverrallClick = () => {
    const finalFile = files?.filter((val: any) => {
      if (val?.status === 'done' || val?.slug?.length) {
        return {
          name: val?.name,
          type: val?.type,
          slug: val?.slug
        };
      }
    });

    const interviewId = location?.pathname?.split('/')[3];
    let body: any;
    if (!applicationStatus) {
      body = {
        interviewId: interviewId,
        question_feedbacks: formattedDataForQuestionSet,
        interview_feedback: {
          if_id: getInterviewOverallFeedback?.data?.uuid,
          send_thankyou_mail: overallThankYouMail ? true : false,
          overall_feedback: overallTextInput,
          overall_rating: overallRate,
          level: null,
          communication_skill: null,
          bad_conduct: null,
          good_conduct: null,
          internal_option: null,
          attachment: finalFile?.length ? finalFile : null,
          meta: !!formatedAdditionalQuestionSet?.length
            ? formatedAdditionalQuestionSet
            : [],
          // new field
          in_next_round: applicationStatus
        }
      };
    } else {
      body = {
        interviewId: interviewId,
        question_feedbacks: formattedDataForQuestionSet,
        interview_feedback: {
          if_id: getInterviewOverallFeedback?.data?.uuid,
          send_thankyou_mail: overallThankYouMail ? true : false,
          overall_feedback: overallTextInput,
          overall_rating: overallRate,
          level: overallLevel,
          communication_skill: Number(overallCommunication),
          bad_conduct: overallBadConduct?.map((i: any) => Number(i)),
          good_conduct: overallGoodConduct?.map((i: any) => Number(i)),
          internal_option: overallInternalCommunication
            ? Number(overallInternalCommunication)
            : overallInternalCommunication,
          in_next_round: applicationStatus,
          meta: !!formatedAdditionalQuestionSet?.length
            ? formatedAdditionalQuestionSet
            : [],
          attachment: finalFile?.length ? finalFile : null
        }
      };
    }

    dispatch({
      type: categoryActions.PUT_EDIT_INTERVIEW_FEEDBACK,
      payload: body
    });
    setIsBtnActive(true);
  };

  const recommendedLevel = [
    {
      value: null,
      label: 'None'
    },
    {
      value: 1,
      label: 'L0'
    },
    {
      value: 2,
      label: 'L1'
    },
    {
      value: 3,
      label: 'L2'
    },
    {
      value: 4,
      label: 'L3'
    },
    {
      value: 5,
      label: 'L4'
    },
    {
      value: 6,
      label: 'L5'
    },
    {
      value: 7,
      label: 'L6'
    },
    {
      value: 8,
      label: 'L7'
    },
    {
      value: 9,
      label: 'L8'
    },
    {
      value: 10,
      label: 'L9'
    },
    {
      value: 11,
      label: 'L10'
    }
  ];

  return (
    <>
      <div className='w-full  h-screen  overflow-y-auto bg-ghost-white relative'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Edit Feedback</title>
        </Helmet>
        <Header />
        <main>
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <section
              style={{ minHeight: '100vh' }}
              className='bg-white rounded-lg p-5 m-8'
            >
              <div className='text-[20px] text-light-blue font-semibold'>
                Edit Interview Feedback
              </div>
              <br />
              <>
                <div className='flex space-x-12 my-4'>
                  <div className='text-[#707070] flex text-[16px] font-semibold'>
                    Applicant Name:{' '}
                    <Tooltip
                      title={getInterviewOverallFeedback?.data?.applicant_name}
                    >
                      {' '}
                      <span className='font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize'>
                        {getInterviewOverallFeedback?.data?.applicant_name}
                      </span>
                    </Tooltip>{' '}
                  </div>
                  <div className='text-[#707070] flex text-[16px] font-semibold'>
                    Job Position:{' '}
                    <Tooltip
                      title={getInterviewOverallFeedback?.data?.job_title}
                    >
                      {' '}
                      <span className='font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize'>
                        {getInterviewOverallFeedback?.data?.job_title}
                      </span>
                    </Tooltip>{' '}
                  </div>
                </div>
                <div className='my-4'>
                  <div className='text-[#707070] text-[16px] font-semibold'>
                    Date & Time:{' '}
                    <span className='font-light'>
                      {moment(
                        getInterviewOverallFeedback?.data?.scheduled_at
                      ).format('MMMM Do, YYYY, h:mm A')}
                    </span>
                  </div>
                </div>
              </>
              <br />
              {getInterviewFeedback &&
                getInterviewFeedback?.data?.map((itm: any, index: number) => (
                  <div
                    className='my-8'
                    key={`${itm.uuid} ${itm.rating} ${itm.content}`}
                  >
                    {/* // <div className="my-8" key={itm.uuid}> */}
                    <div className='flex items-center my-4'>
                      {itm?.categories_list
                        ?.slice(0, 3)
                        ?.filter((i: any) => i.status === 1)
                        ?.map((itm: any, index: number) => (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            key={index}
                            style={{
                              fontSize: '16px',
                              margin: '0px 10px 0px 0px',
                              backgroundColor: '#2D94F31A',
                              width: 'fit-content',
                              height: '28px',
                              padding: '0px 10px',
                              color: '#2D94F3',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {itm?.name}
                          </div>
                        ))}
                      {itm?.categories_list?.length > 3 && (
                        <Tooltip
                          title={itm?.categories_list
                            ?.slice(3)
                            ?.map((i: any) => (
                              <span>
                                {i?.name}
                                <br />
                              </span>
                            ))}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              margin: '0px 10px',
                              backgroundColor: '#2D94F31A',
                              width: 'fit-content',
                              height: '28px',
                              padding: '0px 10px',
                              color: '#1890FF',
                              cursor: 'pointer',
                              borderRadius: '4px'
                            }}
                          >
                            ...
                          </div>
                        </Tooltip>
                      )}
                      {itm?.question_level === 1 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#4E9F09',
                            border: '1px solid #4E9F09',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Easy
                        </span>
                      )}
                      {itm?.question_level === 2 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#D19E37',
                            border: '1px solid #4E9F09',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Medium
                        </span>
                      )}
                      {itm?.question_level === 3 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#C87172',
                            border: '1px solid #C87172',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Hard
                        </span>
                      )}
                      {itm?.question_type === 1 ? (
                        <div className='text-[16px] text-[#707070] font-medium'>
                          Subjective Type
                        </div>
                      ) : (
                        <div className='text-[16px] text-[#707070] font-medium'>
                          Objective Type
                        </div>
                      )}
                    </div>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Question
                      </div>
                      <div className='feedback'>
                        <ReactQuill
                          className='editor'
                          value={itm?.question}
                          readOnly
                          style={{
                            color: '#707070',
                            fontSize: '20px',
                            fontWeight: '500'
                          }}
                          modules={modulesQuestion}
                        />
                      </div>
                    </div>
                    <div>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Answer
                      </div>
                      <div className='feedback'>
                        <ReactQuill
                          className='editor'
                          value={itm?.answer}
                          readOnly
                          style={{
                            color: '#707070',
                            fontSize: '20px',
                            fontWeight: '500'
                          }}
                          modules={modulesQuestion}
                        />
                      </div>
                    </div>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Feedback
                      </div>
                      <Input.TextArea
                        onChange={(e) => handleEditFeedback(e, index)}
                        defaultValue={itm?.content}
                        style={{ minHeight: '5rem' }}
                      />
                      <div className='relative'>
                        {/* {itm?.content?.length > 250 && <p className="text-right text-star-red mt-2 mb-0 pb-0 absolute right-0">Feedback has exceeded max limit </p>} */}
                        {formattedDataForQuestionSet?.[index]?.content?.length >
                          250 && (
                          <p className='text-right text-star-red mt-2 mb-0 pb-0 absolute right-0'>
                            {' '}
                            Feedback has exceeded the maximum limit
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='my-4'>
                      <Rate
                        style={{ color: '#2D94F3' }}
                        onChange={(e) => handleEditRate(e, index)}
                        defaultValue={itm?.rating}
                      />
                    </div>
                    <div className='relative'>
                      {formattedDataForQuestionSet?.[index]?.content === '' &&
                        formattedDataForQuestionSet?.[index]?.rating === 0 && (
                          <p className='text-right text-star-red mt-2 mb-0 pb-0 absolute right-0'>
                            {' '}
                            Feedback or rating one of the field should be filled
                          </p>
                        )}
                    </div>
                    <br />
                  </div>
                ))}
              {/* // -----------------------------// extra question sets //------------------------------*/}
              <section>
                {getInterviewOverallFeedback?.data?.meta?.[0]?.question
                  ?.replace(/<(.|\n)*?>/g, '')
                  ?.trim()?.length > 0 &&
                  getInterviewOverallFeedback?.data?.meta?.[0]?.answer
                    ?.replace(/<(.|\n)*?>/g, '')
                    ?.trim()?.length > 0 && (
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Extra Question Sets
                      </div>
                    </div>
                  )}
                {getInterviewOverallFeedback &&
                  getInterviewOverallFeedback?.data?.meta?.length > 0 &&
                  getInterviewOverallFeedback?.data?.meta?.map(
                    (itm: any, index: number) => (
                      <>
                        {itm?.question?.replace(/<(.|\n)*?>/g, '')?.trim()
                          ?.length > 0 &&
                          itm?.answer?.replace(/<(.|\n)*?>/g, '')?.trim()
                            ?.length > 0 && (
                            <>
                              <div className='my-4'>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Question
                                </div>
                                <div className='feedback'>
                                  <ReactQuill
                                    className='editor'
                                    value={itm?.question}
                                    readOnly
                                    style={{
                                      color: '#707070',
                                      fontSize: '20px',
                                      fontWeight: '500'
                                    }}
                                    modules={modulesQuestion}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Answer
                                </div>
                                <div className='feedback'>
                                  <ReactQuill
                                    className='editor'
                                    value={itm?.answer}
                                    readOnly
                                    style={{
                                      color: '#707070',
                                      fontSize: '20px',
                                      fontWeight: '500'
                                    }}
                                    modules={modulesQuestion}
                                  />
                                </div>
                              </div>
                              <div className='my-4'>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Feedback
                                </div>
                                <Input.TextArea
                                  defaultValue={itm?.content}
                                  onChange={(e) =>
                                    handleEditAdditionalQuestionFeedback(
                                      e,
                                      index
                                    )
                                  }
                                  style={{ minHeight: '5rem' }}
                                />
                                <div className='relative'>
                                  {formatedAdditionalQuestionSet?.[index]
                                    ?.content?.length > 250 && (
                                    <p className='text-right text-star-red mt-2 mb-0 pb-0 absolute right-0'>
                                      {' '}
                                      Feedback has exceeded the maximum limit
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className='my-8'>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Rating
                                </div>
                                <Rate
                                  onChange={(e) =>
                                    handleEditAdditionalQuestionRatting(
                                      e,
                                      index
                                    )
                                  }
                                  style={{ color: '#2D94F3' }}
                                  defaultValue={itm?.rating}
                                />
                              </div>
                              <div className='relative'>
                                {((formatedAdditionalQuestionSet?.[index]
                                  ?.content === '' &&
                                  formatedAdditionalQuestionSet?.[index]
                                    ?.rating === 0) ||
                                  (!formatedAdditionalQuestionSet?.[index]
                                    ?.content &&
                                    formatedAdditionalQuestionSet?.[index]
                                      ?.rating === 0) ||
                                  (formatedAdditionalQuestionSet?.[index]
                                    ?.content === '' &&
                                    formatedAdditionalQuestionSet?.[index]
                                      ?.rating === undefined)) && (
                                  <p className='text-right text-star-red top-[-2.5rem]  mt-2 mb-0 pb-0 absolute right-0'>
                                    {' '}
                                    Feedback or rating one of the field should
                                    be filled
                                  </p>
                                )}
                              </div>
                            </>
                          )}
                      </>
                    )
                  )}
              </section>
              {/* ------------------------------------------------------------------------------------------------ */}
              <hr className='opacity-20' />
              {getInterviewOverallFeedback && (
                <section>
                  {/* --------------------------------// overall feedback //--------------------------------- */}
                  <div className='my-4'>
                    <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                      Overall Feedback <span className='text-star-red'>*</span>
                    </div>
                    <Input.TextArea
                      onChange={(e) => setOverallTextInput(e.target.value)}
                      value={overallTextInput}
                      style={{ minHeight: '5rem' }}
                    />
                    <div className='relative'>
                      {overallTextInput?.length > 250 && (
                        <p className='text-right text-star-red mt-2 mb-0 pb-0 absolute right-0'>
                          Overall Feedback has exceeded the maximum limit
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='my-4'>
                    <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                      Overall Rating <span className='text-star-red'>*</span>
                    </div>
                    <Rate
                      onChange={(e) => setOverallRate(e)}
                      style={{ color: '#2D94F3' }}
                      value={overallRate}
                    />
                  </div>
                  <div className='my-4'>
                    <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                      Move forward with this applicant{' '}
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </div>
                    <div className='test'>
                      <Select
                        value={applicationStatus}
                        className='filter-select w-32 border border-[#F4F7FC] rounded-lg'
                        onChange={(e) => {
                          setApplicationStatus(e);
                        }}
                        placeholder={'Choose'}
                        options={applicantFeedbackOptions}
                      ></Select>
                    </div>
                  </div>
                  {applicationStatus === undefined ||
                  applicationStatus === true ? (
                    <Fragment>
                      <div className='my-4'>
                        <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                          Recommended Levels
                        </div>
                        <Select
                          className='filter-select  border border-[#F4F7FC] rounded-lg'
                          // onChange={(id, key) => handleFeedbackInternalOption(id, key)}
                          onChange={(value) => setOverallLevel(value)}
                          placeholder={'Choose'}
                          options={recommendedLevel}
                          value={overallLevel}
                        />
                      </div>
                      {/* //Communication */}
                      <div className='my-4'>
                        <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                          Was the candidate able to communicate clearly?
                          <span className='text-lg text-star-red pl-1'>*</span>
                        </div>
                        <div className='test'>
                          <Select
                            value={overallCommunication}
                            className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                            onChange={(id, key) => setOverallCommunication(id)}
                            options={formattedCommunication}
                            style={{ maxWidth: '300px' }}
                          ></Select>
                        </div>
                      </div>

                      {/* //Bad conduct */}
                      <div className='my-4'>
                        <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                          Did you notice any of the following case?
                          <span className='text-lg text-star-red pl-1'>*</span>
                        </div>
                        <div className='test'>
                          <Select
                            mode='multiple'
                            allowClear
                            className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                            // filterOption={(input, option) => {
                            //     return option?.label
                            //       ?.toLowerCase()
                            //       ?.includes(input?.toLowerCase())
                            //       ? true
                            //       : false;
                            //   }}
                            onChange={(id, key) => {
                              if (
                                id.includes(
                                  formattedBadConduct?.[
                                    formattedBadConduct?.length - 1
                                  ]?.value
                                )
                              ) {
                                setOverallBadConduct((prev: any) => {
                                  if (
                                    prev?.length === 1 &&
                                    prev.includes(
                                      formattedBadConduct?.[
                                        formattedBadConduct?.length - 1
                                      ]?.value
                                    )
                                  ) {
                                    let indexOfDeleteElement = id.indexOf(
                                      formattedBadConduct?.[
                                        formattedBadConduct?.length - 1
                                      ]?.value
                                    );
                                    id.splice(indexOfDeleteElement, 1);
                                    return id;
                                  }
                                  return [
                                    formattedBadConduct?.[
                                      formattedBadConduct?.length - 1
                                    ]?.value
                                  ];
                                });
                              } else {
                                setOverallBadConduct(id);
                              }
                            }}
                            options={formattedBadConduct}
                            value={overallBadConduct}
                          ></Select>
                        </div>
                      </div>
                      {/* //Good Conduct */}
                      <div className='my-4'>
                        <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                          Any Good Things you noticed about the candidate?
                          <span className='text-lg text-star-red pl-1'>*</span>
                        </div>
                        <div className='test'>
                          <Select
                            mode='multiple'
                            allowClear
                            className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                            onChange={(id, key) => {
                              if (
                                id.includes(
                                  formattedGoodConduct?.[
                                    formattedGoodConduct?.length - 1
                                  ]?.value
                                )
                              ) {
                                setOverallGoodConduct((prev: any) => {
                                  if (
                                    prev?.length === 1 &&
                                    prev.includes(
                                      formattedGoodConduct?.[
                                        formattedGoodConduct?.length - 1
                                      ]?.value
                                    )
                                  ) {
                                    let indexOfDeleteElement = id.indexOf(
                                      formattedGoodConduct?.[
                                        formattedGoodConduct?.length - 1
                                      ]?.value
                                    );
                                    id.splice(indexOfDeleteElement, 1);
                                    return id;
                                  }
                                  return [
                                    formattedGoodConduct?.[
                                      formattedGoodConduct?.length - 1
                                    ]?.value
                                  ];
                                });
                              } else {
                                setOverallGoodConduct(id);
                              }
                            }}
                            // filterOption={(input, option) => {
                            //     return option?.label
                            //       ?.toLowerCase()
                            //       ?.includes(input?.toLowerCase())
                            //       ? true
                            //       : false;
                            //   }}
                            options={formattedGoodConduct}
                            value={overallGoodConduct}
                          ></Select>
                        </div>
                      </div>
                      {/* //Internal Option */}
                      <div className='my-4'>
                        <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                          Internal Option for Shortlisted Candidates
                          {/* <span className="text-lg text-star-red pl-1">*</span> */}
                        </div>
                        <div className='test'>
                          <Select
                            value={overallInternalCommunication}
                            className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                            // onChange={(id, key) => handleFeedbackInternalOption(id, key)}
                            onChange={(id, key) =>
                              setOverallInternalCommunication(id)
                            }
                            placeholder={'Choose'}
                            options={formattedInternalOption}
                          ></Select>
                        </div>
                      </div>
                      {/* {feedbackFileURL && feedbackFileMimeType && (
                    <div className='my-4'>
                      <span className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Optional File
                      </span>
                      {feedbackFileURL &&
                        feedbackFileMimeType ===
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
                          <a
                            href={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                              feedbackFileURL
                            )}`}
                            target='_blank'
                            className='w-full flex md:flex-row  flex-col items-center space-x-3 md:justify-start px-4'
                            rel='noreferrer'
                          >
                            Attachment Link
                          </a>
                        )}
                      {feedbackFileURL &&
                        feedbackFileMimeType !==
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
                          <a
                            href={getInterviewOverallFeedback?.data?.attachment}
                            target='_blank'
                            className='w-full flex md:flex-row  flex-col items-center space-x-3 md:justify-start px-4'
                            rel='noreferrer'
                          >
                            Attachment Link
                          </a>
                        )}
                    </div>
                  )} */}
                    </Fragment>
                  ) : null}
                  <div>
                    <p className='applicant-modal-label'>
                      {'Optional File Upload (.pdf, .txt, .docx, .png, .jpg)'}
                    </p>
                    <div className='cursor-pointer'>
                      <DocumentsComponent
                        maxLimit={5}
                        uploadedFiles={feedbackFile}
                        accept='image/jpg, image/jpeg, image/png, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
                        maxCount={5}
                        multiple
                        onChange={(responseObj: any) => {
                          // setFeedbackFile(responseObj);
                        }}
                        files={files}
                        setFiles={setFiles}
                        size={5}
                      />
                    </div>
                  </div>
                  <div className='my-4'>
                    <Checkbox
                      onChange={(e) =>
                        setOverallThankYouMail(e?.target?.checked)
                      }
                      checked={overallThankYouMail}
                    >
                      Send thank you email
                    </Checkbox>
                  </div>
                  <div className='flex justify-end'>
                    <PrimaryButton
                      onClick={handleEditOverrallClick}
                      disabled={
                        applicationStatus === false
                          ? !!!overallRate ||
                            overallTextInput?.trim()?.length === 0 ||
                            overallTextInput?.length > 250 ||
                            Boolean(
                              files.filter((f: any) => f.slug).length !==
                                files.length
                            )
                          : !!!overallCommunication ||
                            !!!overallGoodConduct?.length ||
                            !!!overallBadConduct?.length ||
                            applicationStatus === undefined ||
                            !!!overallRate ||
                            overallTextInput.trim()?.length === 0 ||
                            overallTextInput.trim()?.length > 250 ||
                            formattedDataForQuestionSet?.filter(
                              (item: any) =>
                                (item?.rating === 0 &&
                                  item?.content?.trim()?.length === 0) ||
                                item?.content?.length > 250
                            ).length > 0 ||
                            (getInterviewOverallFeedback?.data?.meta?.[0]?.question
                              ?.replace(/<(.|\n)*?>/g, '')
                              ?.trim()?.length > 0 &&
                            getInterviewOverallFeedback?.data?.meta?.[0]?.answer
                              ?.replace(/<(.|\n)*?>/g, '')
                              ?.trim()?.length > 0
                              ? formatedAdditionalQuestionSet?.filter(
                                  (item: any) =>
                                    (item?.rating === 0 &&
                                      item?.content?.trim()?.length === 0) ||
                                    item?.content?.length > 250
                                )?.length > 0
                              : files.filter((f: any) => f.slug).length !==
                                files.length
                              ? true
                              : false)
                      }
                      text='Save Changes'
                    />
                  </div>
                </section>
              )}
            </section>
          </Spin>
        </main>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(EditFeedback);
