import { Button, Dropdown } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import Filter from '../Filter';
import { connect, useDispatch } from 'react-redux';
import JobSelect from '../Select/JobSelect';
import filterActions from '../../redux/filters/actions';
import JobStatus from '../Select/StatusSelect';
import ClearFilters from '../Select/ClearButton/index';
import { history } from '../../utilities/configureAxios';
import { getSearchParamsUrl } from '../../helper';
import { useLocation } from 'react-router-dom';
import CoolOffSelect from '../Select/CoolOffSelect';
import { queryStringToJSON } from '../../utilities/routies';
import { DateRangePicker } from '../../@common/DateRangePicker/DateRangePicker';
import moment from 'moment';
import { FaFilter } from 'react-icons/fa';

interface AccordionProps {
  active: boolean;
  isFilterApplied: boolean;
  search: any;
  location: any;
  addApplicantComponent: ReactNode;
  created_at_gte: string | null;
  created_at_lte: string | null;
}

const mapStateToProps = (state: any) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');

  return {
    isFilterApplied: state.filters.isFilterApplied,
    active: state.filters.active,
    search: state.filters.search,
    created_at_gte: state.filters.created_at_gte || startOfMonth,
    created_at_lte: state.filters.created_at_lte || currentDate
  };
};

const Accordion: React.FC<AccordionProps> = ({
  isFilterApplied,
  location,
  addApplicantComponent,
  created_at_lte,
  created_at_gte
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = queryStringToJSON(search);

  useEffect(() => {
    const currentDate = moment().format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');

    if (!created_at_gte || !created_at_lte) {
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          created_at_gte: startOfMonth,
          created_at_lte: currentDate
        }
      });
    }
    if (search === '') {
      dispatch({
        type: filterActions.RESET_STATE1
      });
    }
  }, [search, created_at_gte, created_at_lte, dispatch]);

  const handleClear = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');

    history.push(
      getSearchParamsUrl({
        page: 1,
        graduation_gte: null,
        graduation_lte: null,
        ctc_gte: null,
        ctc_lte: null,
        status: null,
        job_id: null,
        cool_off_status: null,
        created_at_lte: null,
        created_at_gte: null,
        cv_lte: null,
        cv_gte: null,
        tags: [],
        label: null,
        sort: null,
        order: null
      }),
      {}
    );
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        minYear: 1990,
        maxYear: 2030,
        minCtc: 0,
        maxCtc: 100 * 100000,
        selectApp: 'All Jobs',
        appStatus: 'Status',
        isFilterApplied: false,
        search: '',
        coolOffStatus: null,
        created_at_lte: currentDate,
        created_at_gte: startOfMonth,
        minRating: 0,
        maxRating: 10,
        tags: [],
        sort: null,
        order: 'desc'
      }
    });
  };

  const showClearFilter = () => {
    return (
      !!searchParams?.cool_off_status ||
      !!searchParams?.status ||
      !!searchParams?.job_id ||
      !!searchParams?.graduation_gte ||
      !!searchParams?.ctc_gte ||
      !!searchParams?.created_at_lte ||
      !!searchParams?.created_at_gte ||
      !!searchParams?.cv_lte ||
      !!searchParams?.cv_gte ||
      !!searchParams?.tags ||
      !!searchParams?.label ||
      !!searchParams?.sort ||
      !!searchParams?.order
    );
  };

  const handleDateFilterChange = (_: any, dateString: any) => {
    history.push(
      getSearchParamsUrl({
        created_at_gte: dateString[0],
        created_at_lte: dateString[1],
        page: 1
      }),
      {}
    );
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        created_at_gte: dateString[0],
        created_at_lte: dateString[1]
      }
    });
  };

  return (
    <div className='flex items-center mb-2 w-[60%]'>
      <div className='py-1.5 mx-8 mr-4 bg-white rounded-lg shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in'>
        <Dropdown
          overlay={<Filter location={history.location} />}
          placement='bottomLeft'
        >
          <Button className='filter-dropdown'>
            <span>
              <FaFilter className='w-5 h-5 text-filter-color mr-1' />
            </span>
          </Button>
        </Dropdown>
      </div>
      <DateRangePicker
        onChange={handleDateFilterChange}
        value={[moment(created_at_gte), moment(created_at_lte)]}
        allowClear={false}
        className='w-[250px] h-[47px] shadow hover:shadow-md'
        minDate={'2000-01-01'}
      />
      <JobSelect location={history.location} />
      <JobStatus location={history.location} />
      {/* ---Clear filter button-- */}
      {showClearFilter() && <ClearFilters handleClear={handleClear} />}
      {addApplicantComponent}
    </div>
  );
};

export default connect(mapStateToProps)(Accordion);
