import { useLocation } from 'react-router-dom';
import { referralsFilterArray } from '../../../constants/HelperConstants';
import { queryStringToJSON } from '../../../utilities/routies';
import { getSearchParamsUrl } from '../../../helper';
import { history } from '../../../utilities/configureAxios';
import referralActions from '../../../redux/referrals/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isNumber } from 'lodash';

const useAllReferrals = () => {
  const { search } = useLocation();
  const { page, q, status } = queryStringToJSON(search);
  const dispatch = useDispatch();
  const showClearFilter = () => {
    return !!status;
  };
  const handleClearFilter = () => {
    history.push(
      getSearchParamsUrl({
        page: 1,
        status: null
      })
    );
  };
  const handleFilterChange = (value: string) => {
    history.push(
      getSearchParamsUrl({
        page: 1,
        status: isNumber(value) ? String(value) : null
      }),
      {}
    );
  };
  useEffect(() => {
    dispatch({
      type: referralActions.GET_ALL_HR_REFERRALS,
      payload: {
        page: page || 1,
        q,
        status: status?.length ? Number(status) : undefined
      }
    });
  }, [page, q, search, status, dispatch]);
  return {
    status,
    referralStatus: referralsFilterArray,
    handleFilterChange,
    showClearFilter,
    handleClearFilter
  };
};

export default useAllReferrals;
