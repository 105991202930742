import { Input, Modal, Select } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { isString } from 'lodash';
import PrimaryButton from '../../Buttons/Primary';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import jobActions from '../../../redux/jobs/actions';
import FileUpload from '../../FileUpload/ApplicantFileUpload';
import PortfolioFileUpload from '../../FileUpload/PortfolioFileUpoad';
import TextArea from 'antd/lib/input/TextArea';
import AutoComplete from '../../AutoComplete/AutoComplete';
import JobsServices from '../../../services/jobs';
import JobOptionBox from '../../JobOptionBox/JobOptionBox';
import { APPLIED_FROM } from '../../../constants/HelperConstants';
import PhoneNumberInput from '../../PhoneNumberInput';

const mapStateToProps = (state: any) => ({
  jobsForAddApplicant: state.jobs.jobsForAddApplicant
});
interface IJobSelect {
  jobsForAddApplicant: any;
  heading: string;
}

const AddApplicantModal: React.FC<IJobSelect> = ({
  jobsForAddApplicant,
  heading
}) => {
  // const [jobId, setJobId] = useState<{ id?: number; portFolioShow?: boolean }>(
  //   {}
  // );
  const [portFolioShow, setPortFolioShow] = useState<boolean>(false);
  const [taskTitle, setTaskTitle] = useState<string | null>();
  const [fullName, setFullName] = useState<string>('');
  const [files, setFiles] = useState<any>({});
  const [email, setEmail] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [gradYear, setGradYear] = useState<number | null>(null);
  const [lakhs, setLakhs] = useState('');
  const [thousand, setThousand] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [valid, setValid] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [portfolioFileName, setPortfolioFileName] = useState('');
  const [portfolioFileType, setPortfolioFileType] = useState('');
  const [portfolioLinks, setPortfolioLinks] = useState('');
  const [jobTitle, setJobTitle] = useState<any>();
  const [platform, setPlatform] = useState<number | undefined>(undefined);
  const [specifyPlatform, setSpecifyPlatform] = useState<string>('');
  const [jobRefId, setJobRefId] = useState('');
  const { Option } = Select;
  const jobService = new JobsServices();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setGradYear(currentYear);
    setLakhs('00');
    setThousand('00');
  }, []);

  const [errors, setErrors] = useState({
    // mobileNumber: '',
    email: '',
    gradYear: ''
  });
  useEffect(() => {
    const allFieldsFilled =
      jobTitle?.trim().length! > 0 &&
      fullName.trim().length > 0 &&
      email.trim().length > 0 &&
      mobileNumber?.toString().length! > 0 &&
      gradYear?.toString().length! > 0 &&
      lakhs.length > 0 &&
      lakhs.length < 3 &&
      thousand.length > 0 &&
      thousand.length < 3 &&
      fileName?.trim().length > 0;

    const isPlatformValid =
      platform !== 13 || (platform === 13 && specifyPlatform.trim().length > 0);

    setValid(allFieldsFilled && isPlatformValid);
  }, [
    jobTitle,
    fullName,
    email,
    mobileNumber,
    gradYear,
    lakhs,
    thousand,
    fileName,
    platform,
    specifyPlatform
  ]);

  useEffect(() => {
    dispatch({ type: jobActions.GET_ALL_JOBS_FOR_ADD_APPLICANT });
  }, []);

  const onSave = () => {
    const errorsObject = {
      // mobileNumber:
      //   mobileNumber?.toString().length !== 10 ? 'Invalid Mobile number' : '',
      email: validateEmail(email) ? '' : 'Invalid email',
      gradYear:
        gradYear! < new Date().getFullYear() + 7
          ? ''
          : 'Invalid graduation year'
    };
    setErrors(errorsObject);
    if (
      // !errorsObject.mobileNumber &&
      !errorsObject.email &&
      !errorsObject.gradYear
    ) {
      let ctc;
      if (lakhs?.toString().length === 1 && thousand?.toString().length === 1) {
        ctc = Number(`0${lakhs}0${thousand}000`);
      } else if (
        lakhs?.toString().length === 1 &&
        thousand?.toString().length === 2
      ) {
        ctc = Number(`0${lakhs}${thousand}000`);
      } else if (
        lakhs?.toString().length === 2 &&
        thousand?.toString().length === 1
      ) {
        ctc = Number(`${lakhs}0${thousand}000`);
      } else {
        ctc = Number(`${lakhs}${thousand}000`);
      }
      const body = {
        job_id: jobRefId,
        ctc_in_rupees: Number(ctc),
        full_name: fullName,
        graduation_year: Number(gradYear),
        contact: Number(mobileNumber),
        email: email,
        resume: fileName,
        resume_mime_type: fileType,
        ...(platform !== undefined && { applied_from: platform }),
        ...(specifyPlatform.trim() && {
          specific_applied_from: specifyPlatform
        }),
        portfolio_file: portfolioFileName ? portfolioFileName : '',
        portfolio_file_mime_type: portfolioFileType ? portfolioFileType : '',
        portfolio_links: portfolioLinks ? portfolioLinks : ''
      };
      const formData = getFormData(body);
      dispatch({
        type: applicationActions.ADD_APPLICANT,
        payload: {
          body: formData
        }
      });
      setTaskTitle(null);
      setJobTitle(null);
      setEmail('');
      setFullName('');
      setMobileNumber('');
      setIsOpen(false);
      setPortFolioShow(false);
      setPortfolioFileName('');
      setFileName('');
      setFiles({});
      setPlatform(undefined);
      setSpecifyPlatform('');
      setPortfolioLinks('');
    }
  };

  const resetForm = () => {
    setGradYear(new Date().getFullYear());
    setLakhs('00');
    setThousand('00');
    setJobRefId('');
    setPortFolioShow(false);
    setJobTitle('');
    setTaskTitle(null);
    setEmail('');
    setFullName('');
    setMobileNumber('');
    setFileName('');
    setPortfolioFileName('');
    setPortfolioLinks('');
    setFiles({});
    setErrors({
      // mobileNumber: '',
      email: '',
      gradYear: ''
    });
    setIsOpen(false);
    setPlatform(undefined);
  };

  const showModal = () => {
    resetForm();
    setIsOpen(true);
  };
  const handleCancel = () => {
    if (
      email !== '' ||
      fullName !== '' ||
      mobileNumber !== '' ||
      fileName !== '' ||
      portfolioFileName !== '' ||
      portfolioLinks !== '' ||
      platform !== undefined
    ) {
      const res = window.confirm('Are you sure ?');
      if (res) {
        resetForm();
      }
    } else {
      handleChange(null);
      setIsOpen(false);
    }
  };
  const dispatch = useDispatch();

  const handleChange = (data: any) => {
    if (!data) {
      setJobRefId('');
      setPortFolioShow(false);
      setJobTitle('');
      setTaskTitle(null);
    } else {
      setJobRefId(String(data?.job_ref_id));
      setJobTitle(data?.job_title);
      setTaskTitle(data?.id);
      setPortFolioShow(data?.portfolio_required);
    }
    setPortfolioFileName('');
    setPortfolioFileType('');
    setPortfolioLinks('');
    setFiles({});
  };
  const getFormData = (object: any) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
  const getJobTitle = (jobId: any) => {
    let jobTitle = jobsForAddApplicant?.data?.filter(
      (job: any) => jobId === job?.id
    );
    let title = jobTitle[0]?.job_title;
    let id = jobTitle[0]?.job_ref_id;
    let portfolioBoolean = jobTitle[0]?.portfolio_required;
    return [title, id, portfolioBoolean];
  };
  function validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const handlePlatformChange = (value: string) => {
    setPlatform(Number(value));
    setSpecifyPlatform('');
  };

  const children: React.ReactNode[] = [];
  APPLIED_FROM &&
    APPLIED_FROM?.map((item: any) => {
      children.push(<Option key={`${item.value}`}>{item.label}</Option>);
    });

  const customJSX = (item: any): ReactNode => {
    return JobOptionBox({ data: item });
  };
  return (
    <div>
      <div className='mx-4'>
        <PrimaryButton text='Add Applicant' onClick={showModal} />
      </div>
      <Modal
        className='edit-task-modal'
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
        width='1099px'
        footer={null}
      >
        <div className='bg-[#FAFDFF]'>
          <div className='flex justify-between '>
            <div className=''>
              <h3 className='text-xl text-center font-semibold text-blue-zodiac'>
                {heading}
              </h3>
            </div>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
              onClick={handleCancel}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>

          <div className='flex flex-col space-y-[30px]'>
            <div className='grid grid-cols-2'>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  Job Title
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <AutoComplete
                  apiService={jobService.GetAllJobsForFilter}
                  key='job_id'
                  placeholder='All Jobs'
                  isPayload
                  setselectedValue={handleChange}
                  customJSX={customJSX}
                  defaultValue={
                    String(jobRefId)?.length &&
                    isString(String(jobRefId)) &&
                    jobTitle?.length
                      ? `${jobRefId}~${jobTitle}`
                      : ''
                  }
                  // reset={reset}
                  className='edit-input text-base capitalize'
                />
              </div>
              <div className='ml-2'>
                <p className='applicant-modal-label'>
                  Full Name
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  className='edit-input text-base capitalize'
                  value={fullName}
                  onChange={(e: any) => setFullName(e.target.value)}
                  placeholder='Enter full name'
                />
              </div>
            </div>
            <div className='flex justify-between space-x-3'>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  Email
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  className='edit-input text-base'
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  placeholder='Enter email'
                />
                {errors.email !== '' && (
                  <div className='error-message absolute bottom-[-20px]'>
                    {errors.email}
                  </div>
                )}
              </div>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  Mobile Number
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <PhoneNumberInput
                  className='edit-input text-base capitalize'
                  value={mobileNumber ?? ''}
                  onChange={setMobileNumber}
                  placeholder='Enter Mobile Number'
                />
              </div>
            </div>
            <div className='grid grid-cols-3 gap-3'>
              <div>
                <p className='applicant-modal-label'>
                  How did you hear about this role?
                  <span className='text-lg text-star-red pl-1'></span>
                </p>
                <Select
                  allowClear
                  className='edit-input w-full text-base'
                  placeholder='Select Platform'
                  value={platform ? platform?.toString() : undefined}
                  onChange={handlePlatformChange}
                  style={{ width: '100%', overflow: 'auto' }}
                >
                  {children}
                </Select>
                {platform === 13 && (
                  <div className='mt-3'>
                    <p className='applicant-modal-label'>
                      Please Specify
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <Input
                      className='edit-input text-base capitalize'
                      value={specifyPlatform ?? ''}
                      onChange={(e: any) => setSpecifyPlatform(e.target.value)}
                      placeholder='Specify other platform'
                      type='text'
                    />
                    {errors.gradYear !== '' && (
                      <div className='error-message absolute bottom-[-20px]'>
                        {errors.gradYear}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className=''>
                <p className='applicant-modal-label'>
                  Graduation Year
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  className='edit-input text-base capitalize'
                  value={gradYear ?? ''}
                  onChange={(e: any) => setGradYear(e.target.value)}
                  placeholder='Enter graduation year'
                  type='number'
                  max={9999}
                />
                {errors.gradYear !== '' && (
                  <div className='error-message absolute bottom-[-20px]'>
                    {errors.gradYear}
                  </div>
                )}
              </div>
              <div>
                <div>
                  <p className='applicant-modal-label'>
                    Current Annual CTC(INR)
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <div className='flex space-x-3'>
                    <Input
                      className='edit-input text-base capitalize'
                      value={lakhs}
                      onChange={(e: any) => setLakhs(e.target.value)}
                      type='number'
                      placeholder='Enter in lakhs'
                    />
                    <Input
                      className='edit-input text-base capitalize'
                      value={thousand}
                      onChange={(e: any) => setThousand(e.target.value)}
                      type='number'
                      placeholder='Enter in thousands'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className='applicant-modal-label'>
                Resume/CV Upload (.pdf/.docx)
                <span className='text-lg text-star-red pl-1'>*</span>
              </p>
              <div className='cursor-pointer'>
                <FileUpload
                  setFileName={setFileName}
                  fileType={fileType}
                  setFileType={setFileType}
                />
              </div>
            </div>
            {portFolioShow && jobRefId ? (
              <div className='grid grid-cols-2 gap-3'>
                <div>
                  <p className='applicant-modal-label'>
                    Portfolio Upload (.pdf/.docx)
                  </p>

                  <div className='cursor-pointer'>
                    <PortfolioFileUpload
                      setPortfolioFileName={setPortfolioFileName}
                      portfolioFileType={portfolioFileType}
                      setPortfolioFileType={setPortfolioFileType}
                      files={files}
                      setFiles={setFiles}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <p className='applicant-modal-label'>Portfolio Links</p>
                    <TextArea
                      className='edit-input text-base flex ml-0'
                      placeholder='Your best Dribble posts? Behance profile? Enter the links here...'
                      rows={2}
                      name='portfolio-links'
                      value={portfolioLinks}
                      onChange={(e) => {
                        setPortfolioLinks(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
              <PrimaryButton disabled={!valid} text='Save' onClick={onSave} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps)(AddApplicantModal);
