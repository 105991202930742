import { Input, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { APPLICATION_STATUS_SELECT } from '../../../constants/HelperConstants';
import PrimaryButton from '../../Buttons/Primary';
import StatusBox from '../../StatusUpdateComp';
import applicationActions from '../../../redux/applications/actions';

const mapStateToProps = (state: any) => ({
  selectedStatus: state.applications.currentStatus,
  singleApplication: state.applications.singleApplication,
  currentStatus: state.applications.currentStatus,
  rejectedReason: state.applications.rejectedReason,
  formErrors: state.applications.formErrors
});

interface IStatusComp {
  selectedStatus?: any;
  singleApplication?: any;
  currentStatus?: any;
  formErrors?: any;
  rejectedReason?: string;
}

const StatusComp: FC<IStatusComp> = ({
  selectedStatus,
  singleApplication,
  currentStatus,
  rejectedReason,
  formErrors
}) => {
  const [suggestedStatus, setSuggestedStatus] = useState<any>([]);
  const { Option } = Select;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: applicationActions.TOGGLE_UPDATE_STATUS_MODAL,
      payload: false
    });
    if (formErrors?.aliasTo?.length) {
      dispatch({
        type: applicationActions.SET_STATE,
        payload: {
          formErrors: {}
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: applicationActions.SET_REJECTED_REASON,
      payload: singleApplication?.rejection_reason
    });
  }, [singleApplication?.rejection_reason]);

  useEffect(() => {
    setSuggestedStatus(getSuggestedStatus(singleApplication?.status));
  }, [singleApplication?.status]);
  useEffect(() => {
    dispatch({
      type: applicationActions.UPDATE_CURRENT_STATUS,
      payload: singleApplication?.status ? Number(singleApplication?.status) : 1
    });
  }, [singleApplication?.status]);

  const handleChange = (value: any) => {
    dispatch({
      type: applicationActions.UPDATE_CURRENT_STATUS,
      payload: Number(value)
    });
    if (Number(value) === singleApplication?.status) {
      dispatch({
        type: applicationActions.SET_REJECTED_REASON,
        payload: singleApplication?.rejection_reason
      });
    } else {
      dispatch({
        type: applicationActions.SET_REJECTED_REASON,
        payload: null
      });
    }
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: applicationActions.SET_REJECTED_REASON,
      payload: e.target.value
    });
  };

  const onUpdateStatus = () => {
    dispatch({
      type: applicationActions.TOGGLE_UPDATE_STATUS_MODAL,
      payload: true
    });
  };

  function getSuggestedStatus(id: any) {
    const keys = Object.entries(APPLICATION_STATUS_SELECT).map(
      (item) => item[0]
    );
    return keys.slice(id - 1, id + 5);
  }

  // Determine whether the Save button should be disabled
  const isSaveButtonDisabled = () => {
    const isStatusNotSelected =
      selectedStatus === undefined || selectedStatus === null;
    const statusHasChanged =
      Number(singleApplication?.status) !== Number(currentStatus);
    const needsRejectedReason =
      Number(selectedStatus) === 3 ||
      Number(selectedStatus) === 4 ||
      Number(currentStatus) === 3 ||
      Number(currentStatus) === 4;
    const isRejectedReasonMissing = needsRejectedReason && !rejectedReason;
    return (
      isStatusNotSelected ||
      isRejectedReasonMissing ||
      (statusHasChanged === false &&
        rejectedReason === singleApplication?.rejection_reason)
    );
  };

  return (
    <div className='p-5 flex flex-col bg-white rounded-lg w-full relative'>
      <div className='mb-6 '>
        <h2 className='text-[20px]'>Status of Candidate</h2>
        <div className='test'>
          {/* 1 is default state for the select box on which only placeholder should match.*/}
          <Select
            value={
              Number(selectedStatus) === 1 || !selectedStatus
                ? undefined
                : String(selectedStatus)
            }
            defaultValue={
              Number(selectedStatus) === 1 || !selectedStatus
                ? undefined
                : String(selectedStatus)
            }
            className='filter-select w-full border border-[#F4F7FC] rounded-lg'
            onChange={handleChange}
            placeholder={'Select applicant status'}
          >
            {Object.entries(APPLICATION_STATUS_SELECT).map((item) => (
              <Option
                className=''
                value={String(item[0])}
                key={String(item[0])}
              >
                {item[1]}
              </Option>
            ))}
          </Select>
        </div>
        {(selectedStatus === 3 ||
          selectedStatus === 4 ||
          currentStatus === 3 ||
          currentStatus === 4) && (
          <div className='mt-4'>
            <p className='applicant-modal-label'>
              Reason
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            <Input
              className='edit-input text-base'
              value={rejectedReason || ''}
              onChange={handleReasonChange}
              placeholder='Enter Rejection Reason'
            />
          </div>
        )}
      </div>
      <div>
        <h3 className='text-[20px]'>Suggested Next Status</h3>
        <div className='flex flex-wrap'>
          {suggestedStatus?.length > 0 ? (
            suggestedStatus.map((status: any) => (
              <StatusBox
                status={status}
                key={status}
                text={APPLICATION_STATUS_SELECT[status]}
              />
            ))
          ) : (
            <p className='text-center w-full my-4 text-lg text-blue-streak opacity-50 font-semibold'>
              {' '}
              No suggestions available{' '}
            </p>
          )}
        </div>
      </div>
      <div className='mx-auto mt-auto'>
        <PrimaryButton
          text='Save'
          onClick={onUpdateStatus}
          disabled={isSaveButtonDisabled()}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(StatusComp);
