import { Dropdown, Input, Menu, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdKeyboardArrowDown, MdLogout } from 'react-icons/md';
import { connect, useDispatch } from 'react-redux';
import authActions from '../../../redux/auth/actions';
import userActions from '../../../redux/users/actions';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { FaUserEdit } from 'react-icons/fa';
import {
  PROFILE_USER_ROLE,
  USER_ROLE
} from '../../../constants/HelperConstants';
import 'react-phone-number-input/style.css';
import PhoneNumberInput from '../../PhoneNumberInput';

interface IProfile {
  profilePic: any;
  name: any;
  email: any;
  role: number;
  contact: string;
  job_title: string;
}

const mapStateToProps = (state: any) => ({
  profilePic: state.auth.profilePic,
  name: state.auth.name,
  email: state.auth.email,
  role: state.auth.role,
  contact: state.auth.contact,
  job_title: state.auth.job_title
});

const ProfileDropdown: React.FC<IProfile> = ({
  profilePic,
  name,
  email,
  role,
  contact,
  job_title
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updatedContact, setUpdatedContact] = useState<string>('');
  const cookie = new Cookies();
  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setUpdatedContact('+' + contact?.toString() ?? '');
  }, [isOpen, contact]);

  const history = useHistory();

  const dispatch = useDispatch();

  const handleContactChange = (value: any) => {
    setUpdatedContact(value);
  };

  const onUpdate = () => {
    dispatch({
      type: userActions.EDIT_USER_PROFILE,
      payload: {
        contact: Number(updatedContact)
      }
    });
    handleCancel();
  };

  const handleLogout = async () => {
    const arr = Object.keys(cookie.getAll());
    for (let i = 0; i < arr.length; i++) {
      cookie.remove(arr[i]);
    }
    window.location.replace('/');
    dispatch({ type: authActions.LOGOUT, payload: { history } });
  };

  const menu = (
    <Menu className='profile-menu'>
      {role !== USER_ROLE.INTERVIEWER && role !== USER_ROLE.EMPLOYEE && (
        <Menu.Item
          key='editProfile'
          className='text-lg text-blue-radic !py-3 !px-8'
          onClick={() => setIsOpen(true)}
        >
          <div className='flex items-center opacity-80 gap-x-2'>
            <FaUserEdit className='text-xl' />
            <span>Edit Profile</span>
          </div>
        </Menu.Item>
      )}
      <Menu.Item
        key='logout'
        className='text-lg text-blue-radic !py-3 !px-8'
        onClick={handleLogout}
      >
        <div className='flex items-center opacity-80 gap-x-2'>
          <MdLogout className='text-xl' />
          <span>Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown className='cursor-pointer' overlay={menu} trigger={['click']}>
        <MdKeyboardArrowDown size={30} />
      </Dropdown>

      <Modal
        className='edit-profile'
        visible={isOpen}
        onCancel={handleCancel}
        width={'800px'}
        destroyOnClose={true}
        footer={null}
      >
        <div className='flex justify-between m-5'>
          <h3 className='text-lg uppercase font-semibold text-blue-radic'>
            EDIT PROFILE
          </h3>
          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        <div className='flex justify-between m-5 mt-8'>
          <div className='flex flex-col items-center bg-[#F4F5F7] rounded-lg py-5 w-[35%]'>
            <img
              src={profilePic ? profilePic : null}
              alt=''
              className='profile-pic edit-profile-pic'
              width='100px'
              height='100px'
            />
            <p className='text-base text-blue-streak mb-0 pb-0 mt-5'>{name}</p>
            <p className='text-lead text-blue-streak opacity-50'>{job_title}</p>
            <p className='text-base text-blue-streak mb-0 pb-0'>Current Role</p>
            <p className='text-lead text-blue-streak opacity-50 mb-0 pb-0'>
              {PROFILE_USER_ROLE[role]}
            </p>
          </div>
          <div className='flex flex-col justify-between gap-y-10 w-[55%]'>
            <div>
              <p className='text-lg text-blue-streak mb-0'>Personal Details</p>
              <div>
                <p className='text-base text-blue-streak opacity-50 mb-0'>
                  Name<span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  disabled
                  className='edit-input text-base'
                  placeholder='Name'
                  type='text'
                  defaultValue={name}
                />
              </div>
              <div className='mt-4'>
                <p className='text-base text-blue-streak opacity-50 mb-0'>
                  Email<span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Input
                  disabled
                  className='edit-input text-base'
                  placeholder='Email'
                  type='email'
                  defaultValue={email}
                />
              </div>
              <div className='mt-4'>
                <p className='text-base text-blue-streak opacity-50 mb-0'>
                  Contact
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <PhoneNumberInput
                  className='edit-input text-base'
                  placeholder='Contact'
                  value={updatedContact}
                  onChange={handleContactChange}
                />
              </div>
            </div>
            <div className='flex justify-between'>
              <button
                className='h-10 w-28 rounded-md bg-light-blue text-white hover:bg-[#40a9ff]'
                onClick={onUpdate}
              >
                Update
              </button>
              <button
                className='h-10 w-28 rounded-md border text-[#6c757d]] border-[#6c757d]'
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(ProfileDropdown);
