import { Pagination, Spin, Tooltip } from 'antd';
import React, { FC, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import AddModal from '../../Modal/AddJob';
import DeleteModal from '../../Modal/Delete';
import EditModal from '../../Modal/Edit/EditInterviewer';
import interviewerActions from '../../../redux/interviewers/actions';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../../helper';
import { history } from '../../../utilities/configureAxios';

const mapStateToProps = (state: any) => ({
  users: state.users.users,
  loading: state.auth.loading
});

interface InputProps {
  inputHeading: string;
  placeholder: string;
}

interface InputDetails {
  inputDetails: InputProps[];
  users: any;
  loading: boolean;
  location: any;
}

const InterviewerTable: FC<InputDetails> = ({
  inputDetails,
  users,
  loading,
  location
}) => {
  const dispatch = useDispatch();
  const { page } = getCurrentQueryParams();
  useEffect(() => {
    dispatch({
      type: interviewerActions.GET_ALL_INTERVIEWERS,
      payload: { page: page ?? 1 }
    });
  }, [page, dispatch]);
  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <div className='flex flex-col'>
          <div className='flex justify-end mb-5 mx-8'>
            <AddModal heading='Add Interviewer' inputDetails={inputDetails} />
          </div>
          <div className='bg-white shadow-lg mx-8 rounded-2xl overflow-auto min-h-[80%]'>
            <table className='w-full table_transition'>
              <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                <tr className='text-[0.9rem] px-2'>
                  <th className='py-4 w-[10%] max-w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    S.No
                  </th>
                  <th className='py-4 w-[30%] max-w-[30%] text-[#233A5C] text-opacity-50'>
                    Name
                  </th>
                  <th className='py-4 w-[30%] max-w-[30%] text-[#233A5C] text-opacity-50'>
                    Email
                  </th>
                  <th className='py-4 w-[20%] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Job Title
                  </th>
                  <th className='py-4 w-[10%] max-w-[10%] text-[#233A5C] text-opacity-50'>
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {users &&
                  users?.data?.length > 0 &&
                  users?.data?.map((d: any, i: any) => (
                    <tr
                      key={i}
                      className='text-base border-bottom text-blue-zodiac table-text text-left font-normal capitalize mb-0 whitespace-nowrap overflow-auto'
                    >
                      <td className='py-2 text-table-header text-center'>
                        {users?.meta?.pagination?.perPage *
                          (users?.meta?.pagination?.page - 1) +
                          i +
                          1}
                      </td>
                      <td className='py-2 text-table-header'>
                        <div className='font-medium flex items-center space-x-4'>
                          {d.profilePic ? (
                            <img
                              src={d.profilePic}
                              alt=''
                              width='28px'
                              height='28px'
                              className='rounded-full object-contain'
                            />
                          ) : (
                            <div className='user-pic'></div>
                          )}

                          <div className='flex items-center'>
                            <Tooltip placement='bottomLeft' title={d?.name}>
                              <p className='text-blue-zodiac p-0 font-normal table-text mb-0 text-overflow-none max-w-[10rem]'>
                                {d.name}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                      <td className='py-2 text-table-header lowercase text-overflow-none max-w-[7rem]'>
                        <Tooltip placement='bottomLeft' title={d?.email}>
                          {d.email}
                        </Tooltip>
                      </td>
                      <td className='py-2 text-table-header'>
                        {
                          <div className='flex items-center'>
                            <Tooltip
                              placement='bottomLeft'
                              title={d?.job_title}
                            >
                              <p className='text-blue-zodiac font-normal p-0 table-text mb-0 text-overflow-none max-w-[10rem]'>
                                {d.job_title}
                              </p>
                            </Tooltip>
                          </div>
                        }
                      </td>
                      <td className='py-2 text-table-header flex space-x-3'>
                        <EditModal
                          heading='Edit Interviewer Details'
                          name={d.name}
                          id={d.uuid}
                          title={d.job_title}
                          email={d.email}
                        />

                        <DeleteModal
                          heading='Delete Interviewer'
                          desc='Are You Sure You Want To Delete This Interviewer From The ATS?'
                          userId={d.uuid}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {users && users?.data?.length === 0 && (
              <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                Oops! No Interviewers to show
              </div>
            )}
          </div>
          <div className='px-4 lg:px-12 xl:px-16 flex flex-col  justify-center mt-5 mb-10'>
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                current={users?.meta?.pagination?.page}
                defaultPageSize={users?.meta?.pagination?.perPage}
                onChange={(page) =>
                  history.push(getSearchParamsUrl({ page: page }), {})
                }
                total={users?.meta?.pagination?.count}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default connect(mapStateToProps)(InterviewerTable);
